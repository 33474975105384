<template lang="pug">
.header-principal: .container.ff-poppins: .row: .col
    .row
      .col-md-2
        a.pointer(@click="validateAuth") 
          img.me-2(src="../../../assets/LogoColor2.png", title="logo", style="width: 150px; height: 60px;")
      .col-md-6
        .row
          .col-6(v-if="$route.name == 'cliente-cliente-login'")
            router-link.text-decoration-none.me-4(:to="{ name: 'cliente-cliente-reporte-seguro-vida' }" style="color: #34189a; font-weight: 100") 
              .button.btn.btn-primary.text-white.px-5.fw-bold(style="border-radius: 20px; background: #ffa000; font-size: 13px") Soy beneficiario y quiero reportar un siniestro de vida.
        .align-middle.d-inline-block(v-if="mostrarMenu")
          router-link.text-decoration-none.me-4(:to="{ name: 'cliente-cliente-home' }" style="color: #34189a; font-weight: 600; font-weight: 600") Inicio
          router-link.text-decoration-none.me-4(
            v-if="store.mostrarEscrituracion"
            :to="{ name: 'cliente-cliente-escrituracion' }"
            style="color: #34189a; font-weight: 600"
          ) Escrituración
          router-link.text-decoration-none.me-4(:to="{ name: 'cliente-cliente-financiamiento' }" style="color: #34189a; font-weight: 600") Financiamiento
          router-link.text-decoration-none.me-4(:to="{ name: 'cliente-cliente-adjudicaciones' }" style="color: #34189a; font-weight: 600") Adjudicaciones
          router-link.text-decoration-none.me-4(:to="{ name: 'cliente-cliente-materiales-apoyo' }" style="color: #34189a; font-weight: 600") Material Apoyo
          router-link.text-decoration-none.me-4(
            v-if="mostrarTodoSobreMiFinanciamiento"
            :to="{ name: 'cliente-cliente-home' }"
            style="color: #34189a; font-weight: 600"
          ) Documentos
          router-link.text-decoration-none.me-4(
            v-if="mostrarMaterialApoyo"
            :to="{ name: 'cliente-cliente-materiales-apoyo' }"
            style="color: #34189a; font-weight: 600"
          ) Material de Apoyo
          .dropdown.d-inline-block
            button.btn.btn-outline-white.dropdown-toggle.bg-white(data-bs-toggle="dropdown" style="border-radius: 90px; color: #34189a; font-weight: 600") Seguros
            ul.dropdown-menu
              li: a.dropdown-item(v-for="(periodo, index) in seguros" :key="index" href="#" @click.prevent="seleccionarSeguro(periodo.path)") {{ periodo.Name }}
      .col-md-4.mt-3
        .float-end(style="height: 100%; line-height: 60px")
        .align-middle.d-inline-block(v-if="mostrarMenu")
          a.me-2(href="#" @click.prevent="" style="color: #6e7599"): em.far.fa-bell
          a.btn.text-white.fw-bold(href="#" style="background: #5935df; border-radius: 16px; font-size: 14px") 
            | {{ store.nombreCompletoCliente }}
            em.ms-2.far.fa-user
          a.ms-2.text-decoration-none(style="color: #34189a; font-weight: 600" href="#" @click.prevent="salir()") Salir
        .align-middle.d-inline-block(v-if="store.titulo")
          span.fw-bold.fs-5(style="color: #34189a") {{ store.titulo }}
</template>
  
  <style lang="sass">
  .header-principal
    background-color: #fff
    box-shadow: 0px 0px 8px 0px #00000040
    padding-top: 8px
    padding-bottom: 10px
  </style>
  
  <script lang="ts" setup>
  import api from "@/servicios/apiCliente";
  import { useStore } from "../store";
  import { ref, onMounted } from "vue";
  import { useRouter } from "vue-router";
  
  interface MenuSeguros {
    path: string;
    Name: string;
  }
  
  const store = useStore();
  const router = useRouter();
  const mostrarTodoSobreMiFinanciamiento = ref(
    import.meta.env.VITE_CLIENTE_CLIENTE_MOSTRAR_TODO_SOBRE_MI_FINANCIAMIENTO == "1"
  );
  const mostrarMaterialApoyo = ref(import.meta.env.VITE_CLIENTE_CLIENTE_MOSTRAR_MATERIAL_DE_APOYO == "1");
  const seguros = ref([] as MenuSeguros[]);
  const isLoading = ref(false);
  const props = defineProps({
    mostrarMenu: { type: Boolean, default: false }
  });
  
  async function salir() {
    await api.Cliente.Cliente.HomeApi.Logout();
    store.sesionIniciada = false;
    await router.push({ name: "cliente-cliente-login" });
    window.sessionStorage.clear();
  }
  
    const validateAuth = async () => {
  
      try {
        isLoading.value = true;
        const resp = await api.Cliente.Cliente.HomeApi.Sesion();
        if (resp.data) {
          router.push({ name: "cliente-cliente-home" }); // Use router.push
        } else {
          window.location.href = "https://hircasa.com.mx/";
        }
      } catch (error) {
        console.log("Error al verificar la autenticación:", error);
      } finally {
        isLoading.value = false;
      }
    };
  
  async function seleccionarSeguro(periodo: string) {
    await router.push({ name: periodo });
  }
  onMounted(() => {
    seguros.value = [];
    seguros.value.push({ path: "cliente-cliente-seguro-vida", Name: "Vida" });
    seguros.value.push({ path: "cliente-cliente-seguro-danios", Name: "Daños" });
    seguros.value.push({ path: "cliente-cliente-siniestro-generales", Name: "Siniestros" });
  });
  </script>