import globalAxios, { type AxiosInstance, type AxiosPromise, type AxiosResponse } from "axios";

export const BASE_PATH = "";
const DUMMY_BASE_URL = "https://example.com";

interface RequestArgs {
  url: string;
  options: any;
}

export interface ConfigurationParameters {
  accessToken?:
    | string
    | Promise<string>
    | ((name?: string, scopes?: string[]) => string)
    | ((name?: string, scopes?: string[]) => Promise<string>);
  basePath?: string;
  baseOptions?: any;
}

export class Configuration {
  accessToken?:
    | string
    | Promise<string>
    | ((name?: string, scopes?: string[]) => string)
    | ((name?: string, scopes?: string[]) => Promise<string>);
  basePath?: string;
  baseOptions?: any;

  public isJsonMime(mime: string): boolean {
    // eslint-disable-next-line
    const jsonMime: RegExp = new RegExp("^(application/json|[^;/ 	]+/[^;/ 	]+[+]json)[ 	]*(;.*)?$", "i");
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === "application/json-patch+json");
  }

  constructor(param: ConfigurationParameters = {}) {
    this.accessToken = param.accessToken;
    this.basePath = param.basePath;
    this.baseOptions = param.baseOptions;
  }
}

const setBearerAuthToObject = async function (object: any, configuration?: Configuration) {
  if (configuration && configuration.accessToken) {
    const accessToken =
      typeof configuration.accessToken === "function"
        ? await configuration.accessToken()
        : await configuration.accessToken;
    object["Authorization"] = "Bearer " + accessToken;
  }
};

const setSearchParams = function (url: URL, ...objects: any[]) {
  const searchParams = new URLSearchParams(url.search);
  for (const object of objects) {
    for (const key in object) {
      searchParams.set(key, object[key]);
    }
  }
  url.search = searchParams.toString();
};

const objectToFormData = function (obj: any) {
  const fd = new FormData();
  for (const kv of Object.entries(obj)) {
    if (kv[1] instanceof Blob) fd.append(kv[0], kv[1]);
    else if (kv[1] instanceof Array) for (const v of kv[1]) fd.append(kv[0], v);
    else fd.append(kv[0], kv[1] as any);
  }
  return fd;
};

const createRequestFunction = function (axiosArgs: RequestArgs, configuration?: Configuration) {
  return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
    const axiosRequestArgs = {
      ...axiosArgs.options,
      url: (configuration != null && configuration != undefined ? configuration.basePath : basePath) + axiosArgs.url
    };
    return axios.request(axiosRequestArgs);
  };
};

const toPathString = function (url: URL) {
  return url.pathname + url.search + url.hash;
};

const serializeDataIfNeeded = function (value: any, requestOptions: any, configuration?: Configuration) {
  const nonString = typeof value !== "string";
  const needsSerialization =
    nonString && configuration && configuration.isJsonMime
      ? configuration.isJsonMime(requestOptions.headers["Content-Type"])
      : nonString;
  return needsSerialization ? JSON.stringify(value !== undefined ? value : {}) : value || "";
};

const ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    CrearRequestArgs: async (
      localVarPath: string,
      method: string,
      localVarQueryParameter: any,
      requiereAutenticacion: boolean,
      data: any | undefined,
      options: any = {}
    ): Promise<RequestArgs> => {
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method, ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;

      // authentication bearerAuth required
      if (requiereAutenticacion) await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (data instanceof FormData) localVarHeaderParameter["Content-Type"] = "multipart/form-data";
      else localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.withCredentials = true;
      if (data != undefined)
        localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

const ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ApiAxiosParamCreator(configuration);
  return {
    async CrearSolicitador(
      localVarPath: string,
      method: string,
      localVarQueryParameter: any,
      requiereAutenticacion: boolean,
      data: any,
      esBlob: boolean,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.CrearRequestArgs(
        localVarPath,
        method,
        localVarQueryParameter,
        requiereAutenticacion,
        data,
        options
      );
      localVarAxiosArgs.options.responseType = esBlob ? "blob" : "json";
      return createRequestFunction(localVarAxiosArgs, configuration);
    }
  };
};

class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

export enum EstadoRespuesta {
  OK = "OK",
  ALERT = "ALERT",
  INFO = "INFO",
  ERROR = "ERROR",
  NO_AUTORIZADO = "NO_AUTORIZADO"
}

export interface Respuesta {
  estado: EstadoRespuesta;
  mensaje?: string;
  data?: any;
}

export class IdentityApi extends BaseAPI {
  public CrearUsuarioUrl = "/api/Identity/CrearUsuario";

  public async CrearUsuario(
    crearUsuarioBody: Identity.CrearUsuarioBody,
    opcionesAdicionalesAxios?: any
  ): Promise<AxiosResponse<Microsoft.AspNetCore.Identity.IdentityError[]>> {
    if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
    const localVarQueryParameter: any = undefined;
    const data = crearUsuarioBody;
    return ApiFp(this.configuration)
      .CrearSolicitador(
        "/api/Identity/CrearUsuario",
        "PUT",
        localVarQueryParameter,
        true,
        data,
        false,
        opcionesAdicionalesAxios
      )
      .then(request => request(this.axios, this.basePath));
  }

  public ConfirmarCorreoElectronicoUrl = "/api/Identity/ConfirmarCorreoElectronico";

  public async ConfirmarCorreoElectronico(
    userId: number,
    token: string,
    callbackUrl: string,
    opcionesAdicionalesAxios?: any
  ): Promise<AxiosResponse<void>> {
    if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
    const localVarQueryParameter = {
      userId: userId,
      token: token,
      callbackUrl: callbackUrl
    };

    const data: any = undefined;
    return ApiFp(this.configuration)
      .CrearSolicitador(
        "/api/Identity/ConfirmarCorreoElectronico",
        "POST",
        localVarQueryParameter,
        false,
        data,
        false,
        opcionesAdicionalesAxios
      )
      .then(request => request(this.axios, this.basePath));
  }

  public LoginUrl = "/api/Identity/Login";

  public async Login(loginBody: Identity.LoginBody, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
    if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
    const localVarQueryParameter: any = undefined;
    const data = loginBody;
    return ApiFp(this.configuration)
      .CrearSolicitador(
        "/api/Identity/Login",
        "POST",
        localVarQueryParameter,
        false,
        data,
        false,
        opcionesAdicionalesAxios
      )
      .then(request => request(this.axios, this.basePath));
  }
}

export namespace Identity {
  /**
   * @description Esquema para crear un usuario
   */
  export interface CrearUsuarioBody {
    /**
     * @description Debe de ser el correo electrónico del usuario
     */
    usuario?: string;
    contrasenia?: string;
    urlConfirmacionCorreoElectronico?: string;
    /**
     * @default false
     */
    crearEnAuthHIR?: boolean;
  }

  export interface LoginBody {
    usuario: string;
    contrasenia: string;
  }
}

export namespace System {
  export interface TimeSpan {
    ticks: number;
    days: number;
    hours: number;
    milliseconds: number;
    minutes: number;
    seconds: number;
    totalDays: number;
    totalHours: number;
    totalMilliseconds: number;
    totalMinutes: number;
    totalSeconds: number;
  }
}

export namespace Cliente {
  export namespace Cliente {
    export namespace Enums {
      export enum Aplicacion {
        APP_MOVIL = "APP_MOVIL",
        PORTAL_CLIENTE = "PORTAL_CLIENTE"
      }
    }

    export namespace Home {
      /**
       * @description Cuerbo de Cambiar contraseña Token
       */
      export interface CambiarContraseniaTokenBody {
        /**
         * @description Token generado
         */
        token?: string;
        /**
         * @description Contraseña a actualizar
         */
        contrasenia: string;
      }

      /**
       * @description Cuerpo para Generar PIN
       */
      export interface GenerarPINBody {
        /**
         * @description Correo eletrónico o Telefono
         */
        valor?: string;
        /**
         * @description Seleciona medio de contacto 0 MSM y 1 Correo electrónico
         */
        medioContacto: number;
        /**
         * @description Proporciona la ubicación del usuario
         */
        ubicacion?: string;
      }

      /**
       * @description Información principal del cliente
       */
      export interface InformacionHome {
        contrato: DetalleContrato;
        /**
         * @description Nombre completo del cliente
         */
        nombreCompleto?: string;
        /**
         * @description Si se debe mostrar o no la sección de escrituración (Si tiene asignado un asesor de titulación)
         */
        mostrarEscrituracion: boolean;
        porLiquidar: boolean;
        escrituracionID?: string;
        /**
         * @description Lista de beneficiarios del cliente
         */
        beneficiarios?: BeneficiarioDetalle[];
        /**
         * @description Fecha de registro en el Portal del Cliente
         */
        fechaCreacion: string;
        /**
         * @description Lista de banners que se le mostrarán al cliente como publicidad
         */
        banners?: Models.BannerCampania[];
        puntoContacto: Core.Models.PuntoContacto;
        puntoContacto2: Core.Models.PuntoContacto;
        emailPersonal: Core.Models.PuntoContacto;
        telefono3: Core.Models.PuntoContacto;
        telOficina: Core.Models.PuntoContacto;
        email2: Core.Models.PuntoContacto;
        ubicacion: Core.Models.UbicacionLocal;
        /**
         * @description Permite actualzar informacion de la vista del cliente
         */
        puedeActualizarInformacion: boolean;
        /**
         * @description Ruta de la ubicacion en SP
         */
        rutaIne?: string;
      }

      /**
       * @description Cuerpo para la petición de Login
       */
      export interface LoginBody {
        /**
         * @description Correo eletrónico del cliente
         */
        correo: string;
        /**
         * @description Contraseña del cliente
         */
        contrasenia: string;
        canal: Core.Enums.Canal;
      }

      /**
       * @description Respuesta del método RegistrarUsuario
       */
      export interface RegistrarUsuarioBody {
        /**
         * @description Numero de contrato del cliente
         */
        numContrato?: string;
        /**
         * @description Correo eletrónico del cliente
         */
        correo?: string;
        /**
         * @description Contraseña del cliente
         */
        contrasenia?: string;
        /**
         * @description El usuario acepta terminos y condiciones
         */
        aceptarAvisoTerminos: boolean;
        canal?: Core.Enums.Canal;
      }

      /**
       * @description Respuesta del método Login
       */
      export interface RespuestaLogin {
        estado: EstadoRespuesta;
        /**
         * @description Detalle del estado
         */
        mensaje?: string;
        /**
         * @description JWT en caso se usar esa método para autorización
         */
        token?: string;
        /**
         * @description Valor que indica si el cliente ya ha aceptado términos, condiciones y aviso de privacidad
         */
        aceptaTerminosCondicionesAvisoPrivacidad?: boolean;
      }

      /**
       * @description Respuesta del método Ping
       */
      export interface RespuestaPing {
        /**
         * @description Valor que indica si el cliente ya ha aceptado términos, condiciones y aviso de privacidad
         */
        aceptaTerminosCondicionesAvisoPrivacidad?: boolean;
        banners?: Models.BannerCampania[];
      }

      /**
       * @description Respuesta del método RegistrarUsuario
       */
      export interface RespuestaRegistrarUsuario {
        estado: EstadoRespuesta;
        /**
         * @description Mensaje que se proporcionara para notificar estado
         */
        mensaje?: string;
        /**
         * @description Varible para indicar contraseña valida
         */
        contraseniaValida?: boolean;
      }
    }

    export namespace Seguro {
      /**
       * @description Representa un documento de seguro
       */
      export interface DocumentoSeguro {
        tipoDocumento: Core.Models.TipoDocumentoTramiteSeguro;
        /**
         * @description Nombre del documento
         */
        nombreDocumento?: string;
        /**
         * @description Indica si el cliente lo puede descargar
         */
        disponible: boolean;
        /**
         * @description Fecha de carga del documento
         */
        fechaCarga?: string;
        /**
         * @description Método de la API en el controlador Seguro que se debe de usar para obtener el documento
         */
        metodo?: string;
        /**
         * @description Parámetros que se deben de enviar al método para obtener el documento
         */
        parametros?: { [k: string]: string };
      }

      /**
       * @description Clase de Apoyo para Tramite Seguro
       */
      export interface TramiteSeguro {
        /**
         * @description Identificador de Tramite Seguro
         */
        tramiteSeguroId: number;
        estatus?: Core.Enums.EstatusTramiteSeguro;
        /**
         * @description Nombre para Select
         */
        nombreDictamen?: string;
        /**
         * @description Para Agrupacion de Contratos de un mismo Tramite
         */
        folioRecepcion?: string;
      }
    }

    export namespace Siniestro {
      /**
       * @description Clase cuerpo para consumir EnviarCorreoSiniestroVida
       */
      export interface EnviarCorreoSiniestroVidaBody {
        /**
         * @description Nombre del beneficiario
         */
        nombreBeneficiario?: string;
        /**
         * @description Correo del beneficiario
         */
        correo?: string;
        /**
         * @description Nombre del titular
         */
        nombreTitular?: string;
        /**
         * @description Telefono 1 del beneficiario
         */
        telefono1?: string;
        /**
         * @description Telefono 2 del beneficiario
         */
        telefono2?: string;
      }

      export interface ListaDocumentos {
        /**
         * @description DocumentoSiniestroId cifrado
         */
        documentoSiniestroId?: string;
        /**
         * @description Nombre del Documento Cifrado
         */
        nombreDocumento?: string;
      }

      export interface ListaSiniestroconDocumentos {
        /**
         * @description SiniestroId Cifrado
         */
        siniestroId?: string;
        /**
         * @description SiniestroId No Cifrado
         */
        folioSiniestroId: number;
        /**
         * @description Nombre a mostra en el Select
         */
        item?: string;
        tipo: Core.Enums.TipoSinestro;
        /**
         * @description Lista de Rutas de los documentos por Tramite Siniestro
         */
        documentos?: ListaDocumentos[];
        danios: SiniestrosDañosPortalCliente;
        /**
         * @description Validamos que el siniestro exista en DB o es Temporal
         */
        existe: boolean;
      }

      /**
       * @description JSON de Siniestro de Daños
       */
      export interface SiniestrosDañosPortalCliente {
        /**
         * @description Fecha de Suceso
         */
        fechaOcurrido?: string;
        /**
         * @description Motivo del Siniestro
         */
        causaSiniestro?: string;
        /**
         * @description Escribir monto
         */
        mostrarMonto: boolean;
        /**
         * @description Valor aprox del monto
         */
        montoEstimado?: number;
        /**
         * @description CAlle del siniestro
         */
        calle?: string;
        /**
         * @description Numero interior del siniestro
         */
        numInterior?: string;
        /**
         * @description Numero ext del siniestro
         */
        numExterior?: string;
        /**
         * @description Codigo postal
         */
        cp?: number;
        /**
         * @description Colonia del siiestro
         */
        colonia?: string;
        /**
         * @description Alcaldia y municpio del siniestro
         */
        alcaldiaMunicipio?: string;
        /**
         * @description EStado el siniestro
         */
        estado?: string;
        /**
         * @description Nombrel de quien reporta
         */
        nombre?: string;
        /**
         * @description Telefono del contacto
         */
        telefono?: string;
        /**
         * @description Email del contacto
         */
        email?: string;
        /**
         * @description Descripcion del Siniestro
         */
        descripcion?: string;
        rutas: string[];
      }
    }

    /**
     * @description Clase para Actualizar Datos Clientes Referente al Login
     */
    export interface ActualizarAltaCliente {
      /**
       * @description Identificar de ClienteID
       */
      clienteId: number;
      /**
       * @description Cadena Para Contraseña Actual
       */
      passwordActual?: string;
      /**
       * @description Cadena Para Nueva Contraseña
       */
      passwordNueva?: string;
      /**
       * @description Cadena para Validar Contraseña
       */
      repPassword?: string;
      estatus: Core.Enums.EstatusSesion;
    }

    /**
     * @description Representa los detalles del Beneficiario
     */
    export interface BeneficiarioDetalle {
      /**
       * @description Beneficiario id Cifrado
       */
      beneficiarioId?: string;
      /**
       * @description Nombre(s) del Benefifiario
       */
      nombre?: string;
      /**
       * @description Apellido Materno del Beneficiario
       */
      apellidoMaterno?: string;
      /**
       * @description Apellido Paterno del Bneficiario
       */
      apellidoPaterno?: string;
      /**
       * @description Nombre del Beneficiario
       */
      nombreCompleto?: string;
      /**
       * @description Porcentaje
       */
      porcentaje?: number;
      parentesco: Core.Models.Parentesco;
      /**
       * @description Telefono del Beneficiario
       */
      telefono?: string;
    }

    /**
     * @description Clase Custom de Banco
     */
    export interface BodyBanco {
      /**
       * @description Identificador del Banco
       */
      bancoId: number;
      /**
       * @description Nombre del banco
       */
      nombre?: string;
    }

    /**
     * @description Clase para Actualizar Datos Clientes Referente al Login
     */
    export interface BodyDomiciliacion {
      /**
       * @description Cuenta Domiciliacion Id
       */
      cuentaDomiciliacionId?: number;
      /**
       * @description Cliente ID Cifrado
       */
      clienteId?: string;
      /**
       * @description Nombre del Cliente
       */
      nombreCliente?: string;
      /**
       * @description Apellidos del Cliente
       */
      apellidoPaternoCliente?: string;
      /**
       * @description Apellidos del Cliente
       */
      apellidoMaternoCliente?: string;
      telefonoContacto: Core.Models.PuntoContacto;
      /**
       * @description Cuenta Clave del Banco del Cliente
       */
      clabe?: string;
      banco: BodyBanco;
      /**
       * @description Nombre del Tercero
       */
      nombreTercero?: string;
      /**
       * @description Telefono del Tercero
       */
      telefonoTercero?: string;
    }

    /**
     * @description Clase cosomizada de Colonia
     */
    export interface ColoniaBody {
      /**
       * @description Colonia Id
       */
      coloniaId: number;
      /**
       * @description nombre de colonia
       */
      colonia?: string;
    }

    /**
     * @description Clase customizada de contratos
     */
    export interface ContratoBody {
      contratoId: number[];
      numeroContrato: string[];
    }

    /**
     * @description Clase de Apoyo para Descuentos
     */
    export interface DescuentosApoyo {
      /**
       * @description Identificador del Contrato
       */
      contratoId?: number;
      /**
       * @description Identificador del Cliente
       */
      clienteId?: number;
      /**
       * @description Numero de Contrato Completo
       */
      numeroCompleto?: string;
      /**
       * @description Cantidad de Monto Pagado
       */
      montoPagado?: number;
      /**
       * @description Descuento Obtenido por Domiciliacion
       */
      descuentoDomiciliacion?: number;
      /**
       * @description Descuento Obtenido por Post Domiciliacion
       */
      descuentoPostDomiciliacion?: number;
      /**
       * @description Descuento Obtenido por Score
       */
      descuentoScore?: number;
      /**
       * @description Descuento Obtenido de Pago Puntual
       */
      descuentoPagoPuntual?: number;
      /**
       * @description Descuento Obtenido de Pago Puntual Sostenido
       */
      descuentoPagoPuntualSostenido?: number;
      /**
       * @description Descuento Obtenido por Referido
       */
      descuentoReferidos?: number;
      /**
       * @description Fecha de Obtencion de Domiciliacion
       */
      vigenciaDomiciliacionContratar?: string;
      /**
       * @description Fecha de Obtencion de Post Domiciliacion
       */
      vigenciaPostDomiciliacion?: string;
      /**
       * @description Fecha de Obtencion de Score
       */
      vigenciaScore?: string;
      /**
       * @description Fecha de Obtencion de Pago Puntual
       */
      vigenciaPagoPuntual?: string;
      /**
       * @description Fecha de Obtencion de Pago Puntual Sostenido
       */
      vigenciaPagoPuntualSostenido?: string;
      /**
       * @description Fecha de Obtencion de Referidos
       */
      vigenciaReferidos?: string;
    }

    /**
     * @description Clase Saber el Detalle sus contratos de un cliente
     */
    export interface DetalleContrato {
      /**
       * @description Numero total de contratos puntuales
       */
      totalContratosPuntuales: number;
      /**
       * @description Numero total de contratos pendiente de pago
       */
      totalContratosPendientePago: number;
    }

    export interface EnviarCodigoVerificacionMailBody {
      clienteId: number;
    }

    export interface EnviarCodigoVerificacionPuntoContactoBody {
      tipoContacto: Core.Models.TipoContacto;
      valor?: string;
    }

    export interface EnviarCodigoVerificacionSmsBody {
      clienteId: number;
    }

    /**
     * @description Modelo de Tabla para EstudioSocioeconomico de un Cliente
     */
    export interface EstudioSocioeconomico {
      /**
       * @description Identificador Unico de EstudioSocioeconomico
       */
      estudioSocioeconomicoId?: string;
      /**
       * @description Clave Foranea de Cliente
       */
      clienteId: number;
      /**
       * @description Tipo de Servicio a Contratar
       */
      servicioContratar?: number;
      /**
       * @description Tipo de Destino
       */
      destino?: number;
      /**
       * @description Valor de Proyecto
       */
      valorProyecto?: number;
      /**
       * @description Nombre del Cliente
       */
      nombre?: string;
      /**
       * @description Apellido paterno del Cliente
       */
      apellidoPaterno?: string;
      /**
       * @description Apellido Materno del Cliente
       */
      apellidoMaterno?: string;
      /**
       * @description Fecha de Nacimiento del Cliente
       */
      fechaNacimiento?: string;
      /**
       * @description RFC del Cliente
       */
      rfc?: string;
      /**
       * @description Calle del Cliente
       */
      calle?: string;
      /**
       * @description Numero Exterior de donde vive el Cliente
       */
      noExterior?: string;
      /**
       * @description Codigo Postal del Cliente
       */
      codigoPostal?: string;
      /**
       * @description Clave Foranea de Colonia
       */
      coloniaId?: number;
      /**
       * @description Numero de Telefono Personal del Cliente
       */
      telefonoParticular?: string;
      /**
       * @description Numero Celular Personal del Cliente
       */
      telefonoCelular?: string;
      /**
       * @description Email Personal del Cliente
       */
      emailParticular?: string;
      estadoCivil?: Core.Models.EstadoCivil;
      regimenMatrimonial?: Core.Models.RegimenMatrimonial;
      /**
       * @description Numero de Dependentes Economicos
       */
      dependientesEconomicos?: number;
      /**
       * @description Nombre del Conyuge dsi esta casado
       */
      nombreConyuge?: string;
      /**
       * @description Fecha de nacimiento de la conyuge del cliente
       */
      fechaNacimientoConyuge?: string;
      /**
       * @description Clave Foranea de Pais de Conyuge
       */
      paisNacionalidadIdConyuge?: number;
      /**
       * @description Profesion del Cliente
       */
      profesion?: number;
      /**
       * @description Ocupacion Actual del Cliente
       */
      ocupacionActual?: string;
      /**
       * @description Otra Trabajo
       */
      laboralOtro?: string;
      /**
       * @description Donde Labora el Cliente
       */
      empresaLabora?: string;
      /**
       * @description Actividad que desarrolla
       */
      actividadEmpresa?: string;
      /**
       * @description Telefono de la empresa
       */
      telefonoEmpresa?: string;
      /**
       * @description extension de la empresa
       */
      extensionTelefonicaTrabajo?: string;
      /**
       * @description email del trabajo
       */
      emailTrabajo?: string;
      /**
       * @description puesto del cliente
       */
      puestoDesempenia?: string;
      /**
       * @description otro puesto
       */
      otroPuesto?: string;
      /**
       * @description mes en la empresa
       */
      antiguedadLaboralMeses?: number;
      /**
       * @description nombre de jefe inmedianto de la empresa
       */
      nombreJefeInmediato?: string;
      /**
       * @description telefono del jefe inmediato
       */
      telefonoJefeInmediato?: string;
      /**
       * @description extension de telfono del jefe
       */
      extensionJefeInmediato?: string;
      /**
       * @description Sueldo bruto del trabajo
       */
      sueldoNominalBruto?: number;
      /**
       * @description Couta de ISR e IMSS
       */
      isrImss?: number;
      /**
       * @description Sueldo Nomial Neto del trabajo
       */
      sueldoNominalNeto?: number;
      /**
       * @description Honararios del cliente
       */
      honorarios?: number;
      /**
       * @description GRatificaciones del cliente
       */
      gratificaciones?: number;
      /**
       * @description REntas del cliente
       */
      rentas?: number;
      /**
       * @description comisiones del cliente
       */
      comisiones?: number;
      /**
       * @description otros ngresos del cliente
       */
      otrosIngresos?: number;
      /**
       * @description total de ingresos del cliente
       */
      totalIngresos?: number;
      /**
       * @description suma de los ingresos de la esposa del cliente
       */
      totalIngresosConyuge?: number;
      /**
       * @description actividad del conyuge
       */
      actividadConyuge?: string;
      /**
       * @description giro de la empresa del donde trabaja conyuge
       */
      giroConyuge?: string;
      /**
       * @description meses de antigudad de donde trabaja conyuge
       */
      antiguedadLaboralConyuge?: number;
      /**
       * @description RFC del Conyuge
       */
      rfcConyuge?: string;
      /**
       * @description Tipo de Vividenda del enum del HIR-CORE
       */
      tipoViviendaActual?: number;
      /**
       * @description Nombre del Arrendador
       */
      nombreArrendador?: string;
      /**
       * @description Telefono del arrendador
       */
      telefonoArrendador?: string;
      /**
       * @description Deuda de Credito
       */
      pagoMensualCreditos?: number;
      /**
       * @description Credito de Tarjeta
       */
      tarjetaCredito?: number;
      /**
       * @description Valor de Renta que paga
       */
      renta?: number;
      /**
       * @description Costo de Colegiaturas
       */
      colegiaturas?: number;
      /**
       * @description Gasto de la Familia
       */
      gastoFamiliar?: number;
      /**
       * @description Que otras cosas paga
       */
      otrosEgresos?: number;
      /**
       * @description Suma de los Egresos anteriores
       */
      totalEgresos?: number;
      nombreRefFamiliar?: string;
      telefonoRefFamiliar?: string;
      direccionREfFamiliar?: string;
      nombreRefLaboral?: string;
      telefonoRefLaboral?: string;
      direccionREfLaboral?: string;
      nombreRefContacto?: string;
      telefonoRefContacto?: string;
      direccionREfContacto?: string;
      /**
       * @description Es Aval o Obligado Solidario
       */
      esAvalObligadoSolidario: boolean;
      /**
       * @description Verifica ren HirCORE.
       */
      esDeclaradoMoratoriaSuspensionPagos: boolean;
      /**
       * @description Tienen Demanda
       */
      esDemandadoIncumplimientoObligacionesFinancieras: boolean;
      /**
       * @description Nombre del Vendedor
       */
      nombreVendedor?: string;
      /**
       * @description Telefono del Vendedor
       */
      telefonoVendedor?: string;
      /**
       * @description Celular del Vendedor
       */
      celularVendedor?: string;
      /**
       * @description Email del Vendedor
       */
      emailVendedor?: string;
      /**
       * @description Email de donde trabaja el vendedor
       */
      emailLaboralVendedor?: string;
      /**
       * @description Fecha de Nacimiento Vendedor
       */
      fechaNacimientoVendedor?: string;
      estadoCivilVendedor?: Core.Models.EstadoCivil;
      regimenMatrimonialVendedor?: Core.Models.RegimenMatrimonial;
      /**
       * @description Identificaor del pais
       */
      paisNacionalidadIdVendedor?: number;
      /**
       * @description Verificar en HirCore
       */
      proyecto?: string;
      /**
       * @description Valor Adjudicad
       */
      montoAdjudicado?: number;
      /**
       * @description Puntos de Buro de Credito
       */
      scoreBC?: number;
      /**
       * @description Nacionalidad
       */
      paisNacionalidadId: number;
      /**
       * @description Version en Estudio SocioEconomico
       */
      version: number;
      nombreGaranteHipotecario?: string;
      telefonoGaranteHipotecario?: string;
      celularGaranteHipotecario?: string;
      emailGaranteHipotecario?: string;
      emailLaboralGaranteHipotecario?: string;
      fechaNacimientoGaranteHipotecario?: string;
      estadoCivilGaranteHipotecario?: Core.Models.EstadoCivil;
      regimenMatrimonialGaranteHipotecario?: Core.Models.RegimenMatrimonial;
      paisNacionalidadIdGaranteHipotecario?: number;
      /**
       * @description Trabaja Conyuge
       */
      trabajaConyuge?: boolean;
      /**
       * @description JSON de Ingresos
       */
      ingresos?: string;
      /**
       * @description Medio de Contacto
       */
      medioPreferidoContacto?: string;
      /**
       * @description Enviar Notificacion al Asesor
       */
      notificacionAscesor: boolean;
      /**
       * @description Esta Activado AES
       */
      usuarioBloqueadorId?: number;
      /**
       * @description JSON de Documentos Cliente
       */
      documentos?: string;
      /**
       * @description Documento Conyuge
       */
      documentosConyuge?: string;
      /**
       * @description Docuemtnos de Conyuge  GH
       */
      documentosConyugeGaranteHipotecario?: string;
      /**
       * @description JSON de Documentos GH
       */
      documentosGaranteHipotecario?: string;
      /**
       * @description JSON Documeno Vendedor
       */
      documentosVendedor?: string;
      /**
       * @description JSON Documentos Conyuge Vendedor
       */
      documentosConyugeVendedor?: string;
      /**
       * @description Contratos Involucrados
       */
      contratosInvolucrados?: string;
      procedimientoAdjudicacion?: Core.Models.ProcedimientoAdjudicacion;
      /**
       * @description Cantidad de Coutas
       */
      cuotas?: number;
      /**
       * @description Puede Comprobar ingresos
       */
      comprobarIngresos?: boolean;
      /**
       * @description Cliente Radica en el Extranjero
       */
      clienteRadicaExtranjero?: boolean;
      /**
       * @description Cliente tiene doble nacionalidad
       */
      clienteDobleNacionalidad?: boolean;
      /**
       * @description Ingresos provenen del extranjero
       */
      ingresosExtranjeros?: boolean;
      /**
       * @description Descripcion del Inmueble
       */
      inmuebleDescripcion?: string;
      /**
       * @description Valor del inmueble
       */
      inmuebleValorAprox?: number;
      /**
       * @description Esta grabado inmueble
       */
      inmuebleGravado?: boolean;
      /**
       * @description Descripion de Automovil
       */
      automovilDescripcion?: string;
      /**
       * @description Valor de los automoviles
       */
      automovilValorAprox?: number;
      /**
       * @description Estan Grabados
       */
      automovilGravado?: boolean;
      /**
       * @description Edad del Conyuge
       */
      edadConyugue?: number;
    }

    /**
     * @description Modelo de clase para mostrar la información del estudio socioeconómico de una figura
     */
    export interface EstudioSocioeconomicoFigurasApoyoV2 {
      /**
       * @description Identificador Unico de EstudioSocioeconomico
       */
      estudioSocioeconomicoId?: string;
      /**
       * @description Numero de la figura de apoyo
       */
      numero: number;
      figura: TipoFigura;
      /**
       * @description Version del estudio socioeconómico
       */
      version: number;
      /**
       * @description Booleano para saber si un estudio socioeconómico esta bloqueado
       */
      estudioBloqueado: boolean;
      /**
       * @description Nombre del cliente
       */
      nombre?: string;
      /**
       * @description Apellido del cliente
       */
      apellidoPaterno?: string;
      /**
       * @description Apellido materno del cliente
       */
      apellidoMaterno?: string;
      /**
       * @description Fecha de nacimiento del cliente
       */
      fechaNacimiento?: string;
      /**
       * @description RFC del Cliente
       */
      rfc?: string;
      /**
       * @description CURP del Cliente
       */
      curp?: string;
      /**
       * @description Calle de domicilio del cliente
       */
      calle?: string;
      /**
       * @description Numero interior y exterior del cliente
       */
      noExterior?: string;
      /**
       * @description Numero interior de la figura
       */
      noInterior?: string;
      /**
       * @description Colonia del cliente
       */
      codigoPostal?: string;
      /**
       * @description Identificador de la colonia de la figura
       */
      coloniaId?: number;
      /**
       * @description MunicipioAlcaldia del cliente
       */
      municipioAlcaldia?: string;
      /**
       * @description Telefono particular del cliente
       */
      telefonoParticular?: string;
      /**
       * @description Telefono celular del cliente
       */
      telefonoCelular?: string;
      /**
       * @description Email personal del cliente
       */
      emailPersonal?: string;
      /**
       * @description Telefono de trabajo del cliente
       */
      telefonoTrabajo?: string;
      /**
       * @description Email de trabajo del cliente
       */
      emailTrabajo?: string;
      /**
       * @description Calle del trabajo de la figura de apoyo
       */
      calleTrabajo?: string;
      /**
       * @description Número exterior del trabajo de la figura de apoyo
       */
      noExteriorTrabajo?: string;
      /**
       * @description Número interior del trabajo de la figura de apoyo
       */
      noInteriorTrabajo?: string;
      /**
       * @description Código postal del trabajo de la figura de apoyo
       */
      codigoPostalTrabajo?: string;
      /**
       * @description Identificador de la colonia del trabajo de la figura de apoyo
       */
      coloniaIdTrabajo?: number;
      /**
       * @description Municipio o Alcaldía del trabajo de la de la figura de apoyo
       */
      municipioAlcaldiaTrabajo?: string;
      /**
       * @description Número de dependietes económicos de la figura de apoyo
       */
      numeroDependientes?: number;
      estadoCivil?: Core.Models.EstadoCivil;
      regimenMatrimonial?: Core.Models.RegimenMatrimonial;
      /**
       * @description Nombre del conyuge de la figura de apoyo
       */
      nombreConyuge?: string;
      /**
       * @description Apellido Paterno de la figura de apoyo
       */
      apellidoPaternoConyuge?: string;
      /**
       * @description Apellido Materno de la figura de apoyo
       */
      apellidoMaternoConyuge?: string;
      /**
       * @description Cliente Radica en el Extranjero
       */
      clienteRadicaExtranjero?: boolean;
      /**
       * @description Cliente tiene doble nacionalidad
       */
      clienteDobleNacionalidad?: boolean;
      /**
       * @description Ingresos provenen del extranjero
       */
      ingresosExtranjeros?: boolean;
      /**
       * @description Identificador de la profesion de la figura de apoyo
       */
      profesion?: number;
      /**
       * @description Número de las empresas en que trabaja de la figura de apoyo
       */
      numeroEmpresasTrabaja?: number;
      /**
       * @description Booleano para sabre si la figura de apoyo puede comprobar ingresos
       */
      comprobarIngresos?: boolean;
      /**
       * @description Pago mensual de un prestamo de la figura de apoyo
       */
      pagoMensualPrestamo?: number;
      /**
       * @description Pago mensual de la tarjeta de crédito de la figura de apoyo
       */
      pagoTarjetaCredito?: number;
      /**
       * @description Pago mensual de la renta de la figura de apoyo
       */
      pagoRenta?: number;
      /**
       * @description Pago mensual de la colegiatura de la figura de apoyo
       */
      pagoColegiaturas?: number;
      /**
       * @description Gastos mensuales de la familia de la figura de apoyo
       */
      pagoGastoFamiliar?: number;
      /**
       * @description Otros egresos / gastos de la de la figura de apoyo
       */
      otrosEgresos?: number;
      tipoViviendaActual?: Core.Models.TipoVivienda;
      /**
       * @description Nombre del arrendador de la figura de apoyo
       */
      nombreArrendador?: string;
      /**
       * @description Nombre del segundo arrendador de la figura de apoyo
       */
      nombreArrendador2?: string;
      /**
       * @description Valor del inmueble de la figura de apoyo
       */
      inmuebleValorAproxPatrimonio?: number;
      /**
       * @description Esta grabado inmueble de la figura de apoyo
       */
      inmuebleGravadoPatrimonio?: boolean;
      /**
       * @description Descrición del automovil de la figura de apoyo
       */
      automovilDescripcion?: string;
      /**
       * @description Valor de los automoviles de la figura de apoyo
       */
      automovilValorAproxPatrimonio?: number;
      /**
       * @description Si se encuentra pagando el automovil de la figura de apoyo
       */
      pagandoAutomovilPatrimonio?: boolean;
      /**
       * @description Teléfono del arrendador de la figura de apoyo
       */
      telefonoArrendador?: string;
      /**
       * @description Institución financiera del inmueble de la figura de apoyo
       */
      institucionFinanciera?: string;
      /**
       * @description Observaciones generales
       */
      observacionesGenerales?: string;
    }

    /**
     * @description Modelo estudio socioeconomico de un cliente v2
     */
    export interface EstudioSocioeconomicoV2 {
      /**
       * @description Identificador Unico de EstudioSocioeconomico
       */
      estudioSocioeconomicoId?: string;
      /**
       * @description Clave Foranea de Cliente
       */
      clienteId: number;
      /**
       * @description Versionado del Estudio Socioeconómico
       */
      version: number;
      /**
       * @description Valor Booleano de Estudio bloqueado
       */
      estudioBloqueado: boolean;
      /**
       * @description Monto Adjudicado
       */
      montoAdjudicado?: number;
      /**
       * @description Mensualidad
       */
      mensualida?: number;
      proyecto?: Core.Models.TipoProyecto;
      /**
       * @description Booleano para saber si tiene contrato de compraventa
       */
      tieneContratoPreventa?: boolean;
      /**
       * @description Valor pactado
       */
      valorPactado?: number;
      /**
       * @description Booleano para saber si el inmueble esta gravado
       */
      inmuebleGravado?: boolean;
      usoInmueble?: Core.Enums.UsoInmueble;
      /**
       * @description Descrición del inmueble a comprar
       */
      inmuebleDescripcion?: string;
      /**
       * @description Valor del proyecto
       */
      valorProyecto?: number;
      tipoConstruccion?: Core.Enums.TipoConstruccion;
      /**
       * @description Booleano para saber si tiene permiso de construcción
       */
      tienePermisoConstrunccion?: boolean;
      /**
       * @description Booleano para saber si el vendedor es persona física
       */
      vendedorEsPersonaFisica?: boolean;
      /**
       * @description Nombre del vendedor
       */
      nombreVendedor?: string;
      /**
       * @description Apellido paterno del vendedor
       */
      apellidoPaternoVendedor?: string;
      /**
       * @description Apellido materno del vendedor
       */
      apellidoMaternoVendedor?: string;
      /**
       * @description Teléfono del vendedor
       */
      telefonoVendedor?: string;
      /**
       * @description Correo del vendedor
       */
      correoVendedor?: string;
      estadoCivilVendedor?: Core.Models.EstadoCivil;
      regimenMatrimonialVendedor?: Core.Models.RegimenMatrimonial;
      /**
       * @description Nombre del conyuge del vendedor
       */
      nombreConyugeVendedor?: string;
      /**
       * @description Apellido paterno del conyuge del vendedor
       */
      apellidoPaternoConyugeVendedor?: string;
      /**
       * @description Aoellido materno del conyuge del vendedor
       */
      apellidoMaternoConyugeVendedor?: string;
      /**
       * @description Nombre del Desarrollo
       */
      nombreDesarrollo?: string;
      /**
       * @description Nombre del Contacto de desarrollo
       */
      nombreContactoDesarrollo?: string;
      /**
       * @description Teléfono del Desarrollo
       */
      telefonoDesarrollo?: string;
      /**
       * @description Correo del desarrollo
       */
      correoDesarrollo?: string;
      /**
       * @description Nombre del cliente
       */
      nombre?: string;
      /**
       * @description Apellido del cliente
       */
      apellidoPaterno?: string;
      /**
       * @description Apellido materno del cliente
       */
      apellidoMaterno?: string;
      /**
       * @description Fecha de nacimiento del cliente
       */
      fechaNacimiento?: string;
      /**
       * @description RFC del Cliente
       */
      rfc?: string;
      /**
       * @description CURP del Cliente
       */
      curp?: string;
      /**
       * @description Calle de domicilio del cliente
       */
      calle?: string;
      /**
       * @description Numero interior y exterior del cliente
       */
      noExterior?: string;
      /**
       * @description Numero interior
       */
      noInterior?: string;
      /**
       * @description Colonia del cliente
       */
      codigoPostal?: string;
      /**
       * @description Identificador de colonia
       */
      coloniaId?: number;
      /**
       * @description MunicipioAlcaldia del cliente
       */
      municipioAlcaldia?: string;
      /**
       * @description Telefono particular del cliente
       */
      telefonoParticular?: string;
      /**
       * @description Telefono celular del cliente
       */
      telefonoCelular?: string;
      /**
       * @description Email personal del cliente
       */
      emailPersonal?: string;
      /**
       * @description Telefono de trabajo del cliente
       */
      telefonoTrabajo?: string;
      /**
       * @description Email de trabajo del cliente
       */
      emailTrabajo?: string;
      /**
       * @description Calle del trabajo
       */
      calleTrabajo?: string;
      /**
       * @description Numero exterior del trabajo
       */
      noExteriorTrabajo?: string;
      /**
       * @description Numero Interior del trabajo
       */
      noInteriorTrabajo?: string;
      /**
       * @description Codigo postal del trabajo
       */
      codigoPostalTrabajo?: string;
      /**
       * @description ColoniaId del trabajo
       */
      coloniaIdTrabajo?: number;
      /**
       * @description Municipio o Alcaldia del trabajo
       */
      municipioAlcaldiaTrabajo?: string;
      /**
       * @description Numero de dependientes económicos
       */
      numeroDependientes?: number;
      estadoCivil?: Core.Models.EstadoCivil;
      regimenMatrimonial?: Core.Models.RegimenMatrimonial;
      /**
       * @description Nombre de conyuge del cliente
       */
      nombreConyuge?: string;
      /**
       * @description Apellido Paterno de Conyuge del cliente
       */
      apellidoPaternoConyuge?: string;
      /**
       * @description Apellido materno de conyugel del cliente
       */
      apellidoMaternoConyuge?: string;
      /**
       * @description Cliente Radica en el Extranjero
       */
      clienteRadicaExtranjero?: boolean;
      /**
       * @description Cliente tiene doble nacionalidad
       */
      clienteDobleNacionalidad?: boolean;
      /**
       * @description Booleano para saber si los Ingresos provienen del extranjero
       */
      ingresosExtranjeros?: boolean;
      /**
       * @description Identificador de la profesión del cliente
       */
      profesion?: number;
      /**
       * @description Numero de empresas en las que trabaja el cliente.
       */
      numeroEmpresasTrabaja?: number;
      /**
       * @description Booleano para saber si el cliente puede comprobar ingresos.
       */
      comprobarIngresos?: boolean;
      /**
       * @description Pago mensual de los prestamos del cliente
       */
      pagoMensualPrestamo?: number;
      /**
       * @description PAgo mensual de la tarjeta de crédito del cliente
       */
      pagoTarjetaCredito?: number;
      /**
       * @description Renta mensual del clientge
       */
      pagoRenta?: number;
      /**
       * @description Pago de colegiaturas mensuales del cliente
       */
      pagoColegiaturas?: number;
      /**
       * @description Gasto familiar mensual del cliente
       */
      pagoGastoFamiliar?: number;
      /**
       * @description Otros egresos o pagos del cliente
       */
      otrosEgresos?: number;
      /**
       * @description Mensualidad de Hircasa
       */
      mensualidadHirCasa?: number;
      tipoViviendaActual?: Core.Models.TipoVivienda;
      /**
       * @description Nombre del arrendador del cliente
       */
      nombreArrendador?: string;
      /**
       * @description Nombre del segundo arrendador del cliente
       */
      nombreArrendador2?: string;
      /**
       * @description Valor del inmueble
       */
      inmuebleValorAproxPatrimonio?: number;
      /**
       * @description Descripción del autimovil del cliente
       */
      automovilDescripcion?: string;
      /**
       * @description Valor de los automoviles
       */
      automovilValorAproxPatrimonio?: number;
      /**
       * @description Si se encuentra pagando el automovil
       */
      pagandoAutomovilPatrimonio?: boolean;
      /**
       * @description Teléfono del arrendador del cliengte
       */
      telefonoArrendador?: string;
      /**
       * @description Institucion financiera a la que pertenece el inmueble
       */
      institucionFinanciera?: string;
      /**
       * @description Observaciones generales
       */
      observacionesGenerales?: string;
    }

    export enum EtapaSeguroVida {
      Solicitd = "Solicitd",
      Revision = "Revision",
      Analisis = "Analisis",
      Resultado = "Resultado",
      Certificado = "Certificado",
      Dictamen = "Dictamen"
    }

    /**
     * @description Clase Saber el Detalle sus contratos de un cliente
     */
    export interface FacturacionBody {
      /**
       * @description Ubicacon Id
       */
      ubicacionId: number;
      /**
       * @description Numero total de contratos puntuales
       */
      nombre?: string;
      /**
       * @description Numero total de contratos pendiente de pago
       */
      apellidoPaterno?: string;
      /**
       * @description Apellido materno de quien factura
       */
      apellidoMaterno?: string;
      /**
       * @description RFC del cliente
       */
      rfc?: string;
      /**
       * @description Regimen Fiscal del cliente
       */
      regimenFiscalId?: number;
      /**
       * @description CAlle de vivienda del cliente
       */
      calle?: string;
      /**
       * @description Numero de la ubicacion
       */
      numero?: string;
      /**
       * @description Codigo postal
       */
      cp?: string;
      colonia: ColoniaBody;
      /**
       * @description Nombre de la alcadia o municipio
       */
      alcaldiaMunicipio?: string;
      /**
       * @description Estado de ubicacion
       */
      estado?: string;
      /**
       * @description Telefono particular del cliente
       */
      telefonoParticular?: string;
      /**
       * @description Telefono celular
       */
      telefonoCelular?: string;
      contrato: ContratoBody;
    }

    /**
     * @description Clase Auxulizar para tener menus de material de apoyo
     */
    export interface MaterialApoyo {
      /**
       * @description Icono ubicado en carpeta public de vue
       */
      icon?: string;
      /**
       * @description Nombre del Menu
       */
      nombre?: string;
      /**
       * @description Ruta del Index.ts de vue
       */
      path?: string;
    }

    /**
     * @description Clase de tabla de figura auxiliar Cotitulat GH OS
     */
    export interface ObligadoSolidario {
      /**
       * @description Identitifcador AES
       */
      estudioSocioeconomicoId?: string;
      /**
       * @description Identificador de Cliente
       */
      clienteId: number;
      /**
       * @description Nombre de la figura
       */
      nombre?: string;
      /**
       * @description apellido paterno la figura
       */
      apellidoPaterno?: string;
      /**
       * @description apellido materno figura
       */
      apellidoMeterno?: string;
      /**
       * @description parenteco con el cliente
       */
      parentezco?: string;
      /**
       * @description fecha de nacimiento de figura
       */
      fechaNacimiento?: string;
      /**
       * @description identificador de nacionaidad figura
       */
      paisNacionalidadId?: number;
      /**
       * @description estado de la figura
       */
      estadoNacimiento?: string;
      /**
       * @description municipio de la figura
       */
      municipioAlcaldia?: string;
      estadoCivil?: Core.Models.EstadoCivil;
      regimenMatrimonial?: Core.Models.RegimenMatrimonial;
      /**
       * @description RFC de la figura
       */
      rfc?: string;
      /**
       * @description calle de la figura
       */
      calle?: string;
      /**
       * @description numero de la calle de la figura
       */
      numExt?: string;
      /**
       * @description coonia de donde vive la figura
       */
      colonia?: string;
      /**
       * @description estado de domicilio
       */
      estadoDomicilio?: string;
      /**
       * @description municipio o alcaldia de la figura
       */
      municipioAlcaldiaDocumento?: string;
      /**
       * @description Ciudad de la figura
       */
      ciudad?: string;
      /**
       * @description codigo postal
       */
      cp?: string;
      /**
       * @description telefono de contacto de la figura
       */
      telParticular?: string;
      /**
       * @description telefono de contacto de la figura
       */
      telCelular?: string;
      /**
       * @description correo particular figura
       */
      correoElectronico?: string;
      /**
       * @description dependientes economico
       */
      numDependientesEconomicos: number;
      /**
       * @description tipo de vivienda
       */
      vivienda?: number;
      /**
       * @description arrendador
       */
      nombreArrendador?: string;
      /**
       * @description medios de contactos
       */
      telFijoArrendador?: string;
      /**
       * @description medios de contactos
       */
      telCelArrendador?: string;
      /**
       * @description aque se dedica el arrendador
       */
      ocupacionActual?: string;
      /**
       * @description aque se dedica el arrendador
       */
      otraOcupacion?: string;
      /**
       * @description nombre de la empresa
       */
      nombreEmpresa?: string;
      /**
       * @description actividad de la empresa
       */
      actividadEmpresa?: string;
      /**
       * @description medio de contacto de la emprea
       */
      telEmpresa?: string;
      /**
       * @description medio de contacto de la emprea
       */
      numExtEmpresa: number;
      /**
       * @description medio de contacto de la emprea
       */
      correoEmpresa?: string;
      /**
       * @description puesto que desempeña el arrendador
       */
      puesto?: string;
      /**
       * @description nombre del jefe directo
       */
      nombreJefeDirecto?: string;
      /**
       * @description su profecion
       */
      profesion?: number;
      /**
       * @description años en mes de antiguedad
       */
      antiguedadAnios: number;
      /**
       * @description años en mes de antiguedad
       */
      antiguedadMeses: number;
      /**
       * @description otros gastos
       */
      otrosEgresos?: number;
      /**
       * @description gasto familiar
       */
      gastoFamiliar?: number;
      /**
       * @description gasto en colegiaturas
       */
      colegiaturas?: number;
      /**
       * @description gasto en renta
       */
      renta?: number;
      /**
       * @description gasto en tarjetas
       */
      tarjetaCredito?: number;
      /**
       * @description pago de creditos
       */
      pagoMensualCreditos?: number;
      /**
       * @description valor de bienes
       */
      valorAproximadoAutomoviles?: number;
      /**
       * @description cantidad de autos
       */
      cantidadAutomoviles?: number;
      /**
       * @description relacion con el patrimonio
       */
      relacionPatrimonialAutomoviles?: string;
      /**
       * @description valor de inmubelres
       */
      valorAproximadoInmueble?: number;
      /**
       * @description relacion con el patrimonio
       */
      cantidadInmueble?: number;
      /**
       * @description Cargo Mensual Creditos
       */
      cagoMensualCreditos?: number;
      /**
       * @description Total de ingresos comprobables
       */
      totalIngresosComprobabes?: number;
      /**
       * @description Total de comisiones
       */
      comisiones?: number;
      /**
       * @description total de ingreso de rentas
       */
      rentas?: number;
      /**
       * @description total de ingresos de gratificaciones
       */
      gratificaciones?: number;
      /**
       * @description total de pago de isr o imss
       */
      isrImss?: number;
      /**
       * @description Sueldo nomial bruto
       */
      sueldoNominalBruto?: number;
      /**
       * @description Inreso del Conyuge
       */
      ingresosConyuge?: number;
      /**
       * @description otros ingresos
       */
      otrosIngresos?: number;
      /**
       * @description puede comprobar igresos
       */
      puedeComprarIngresos: boolean;
      /**
       * @description relacion con inmueble
       */
      relacionInmueble?: string;
      /**
       * @description gravado en inmueble
       */
      gravadoInmueble: boolean;
      /**
       * @description grabado en automoviles
       */
      gravadoAutomoviles: boolean;
      /**
       * @description codigo postal
       */
      codigoPostal?: string;
      /**
       * @description dentificador de colonia
       */
      coloniaId: number;
      /**
       * @description identificador de Colonia figuras
       */
      coloniaIdOS: number;
      /**
       * @description notificar asesor a HIR-CORE
       */
      notificacionAscesor: boolean;
      /**
       * @description JSON Ingresos
       */
      ingresos?: string;
      /**
       * @description Suledo Nominal Neto
       */
      sueldoNominalNeto?: number;
    }

    /**
     * @description Cuerpo para las Colinia
     */
    export interface ObtenerColoniasBody {
      /**
       * @description Identificador de la colinia
       */
      coloniaId: number;
      /**
       * @description nombre de la colonia
       */
      colonia?: string;
      /**
       * @description Codigo postal
       */
      codigoPostal?: string;
      /**
       * @description ciudad
       */
      ciudad?: string;
      /**
       * @description estado
       */
      estado?: string;
      /**
       * @description pais
       */
      pais?: string;
    }

    export enum ObtenerMaterialApoyo {
      SOLO_TITULOS = "SOLO_TITULOS",
      SOLO_TEXTO_SUBTITULOS = "SOLO_TEXTO_SUBTITULOS",
      TODOS = "TODOS"
    }

    export interface OnboardingIncodeBody {
      curpValidationRetries: number;
      env?: string;
      flowType?: string;
      idCaptureRetries: number;
      idCaptureTimeout: number;
      interviewCode?: string;
      interviewId?: string;
      selfieCaptureRetries: number;
      selfieCaptureTimeout: number;
      token?: string;
      finishStatus?: string;
    }

    /**
     * @description Clase para Procentaje y Prima Mensual
     */
    export interface PrimaMensual {
      /**
       * @description Porcentaje de la Prima
       */
      porcentajePrima: number;
      /**
       * @description Valor de la Prima Mensual
       */
      valorPrima: number;
    }

    /**
     * @description Clase para vista de proceso escrituracion
     */
    export interface ProgresoEscrituracion {
      /**
       * @description nombre completo del cliente
       */
      nombreCompletoCliente?: string;
      /**
       * @description identificador del estudio socioeconomico
       */
      estudioSocioeconomicoId: number;
      /**
       * @description proeso de linea vida
       */
      progreso: number;
      /**
       * @description Se peude editar el AES
       */
      estudioSocioeconomicoBloqueado: boolean;
      /**
       * @description consulta de Buro e Credito productivo
       */
      autorizacionConsultaBuroCredito: boolean;
      /**
       * @description Documetnos necesarios del cliente
       */
      documentos?: Core.Models.EstudioSocioeconomicoDocumento[];
      /**
       * @description documentos necesarios de conyuge
       */
      documentosConyuge?: Core.Models.EstudioSocioeconomicoDocumento[];
      /**
       * @description documentos necesarios Vendedor
       */
      documentosVendedor?: Core.Models.EstudioSocioeconomicoDocumento[];
      /**
       * @description documentos necesarios GH
       */
      documentosGaranteHipotecario?: Core.Models.EstudioSocioeconomicoDocumento[];
      documentosObligadoSolidario: Core.Models.EstudioSocioeconomicoDocumento[][];
      documentosCotitular: Core.Models.EstudioSocioeconomicoDocumento[][];
      /**
       * @description documentos necesarios Vendedor Conyuge vendedor
       */
      documentosConyugeVendedor?: Core.Models.EstudioSocioeconomicoDocumento[];
      llavesObligadoSolidario: string[];
      llavesCotitular: string[];
      llavesGaranteHipotecario: string[];
    }

    /**
     * @description Clase para la linea de vida del Seguro
     */
    export interface ProgresoSeguroVida {
      /**
       * @description Etapa de Seguro de Vida
       */
      etapas?: EtapaSeguroVida[];
      /**
       * @description Progreso del Seguro de Vida
       */
      progreso: number;
    }

    export interface RespuestaFuenteIngreso {
      /**
       * @description Identificador de estudio socioeconómico encriptado
       */
      estudioSocioeconomicoId?: string;
      tipoFuenteIngreso: Core.Enums.TipoFuenteIngreso;
      actividadEconomica?: Core.Enums.ActividadEconomica;
      /**
       * @description Nombre de la empresa en la que trabaja
       */
      nombreEmpresa?: string;
      /**
       * @description Identificador del giro de la empresa
       */
      giroEmpresa?: number;
      /**
       * @description Teléfono de contacto del trabajo del cliente
       */
      telefono?: string;
      /**
       * @description Extenció de nuemro telefonico del la empresa / trabajo
       */
      extension?: string;
      /**
       * @description Email de la empresa / trabajo
       */
      email?: string;
      /**
       * @description Puesto que desempeña
       */
      puesto?: string;
      /**
       * @description Antigüedad en meses de la fuente de ingresos
       */
      antiguedad?: number;
      /**
       * @description Nombre del Jefe directo
       */
      nombreJefe?: string;
      /**
       * @description Número teléfonico del cliente
       */
      telefonoJefe?: string;
      /**
       * @description Ingreso bruto
       */
      ingresoBruto?: number;
      /**
       * @description Impusetos
       */
      impuestos?: number;
      /**
       * @description Calle de la fuente de ingreso
       */
      calle?: string;
      /**
       * @description Número Exterior de la fuente de ingreso
       */
      noExterior?: string;
      /**
       * @description Número interior de la funete de ingreso
       */
      noInterior?: string;
      /**
       * @description Código Postal de la fuente de ingreso
       */
      codigoPostal?: string;
      /**
       * @description Identificador de la colonia de la fuente de ingreso
       */
      coloniaId?: number;
      /**
       * @description Municipio o Alcaldia de la fuente de ingreso
       */
      municipioAlcaldia?: string;
      /**
       * @description Booleano para saber si es socio de la fuente de ingreso
       */
      esSocio?: boolean;
      /**
       * @description Descripción de la funete de ingresos
       */
      descripcion?: string;
      /**
       * @description Segunda descripción de la fuente de ingresos
       */
      descripcion2?: string;
      tipoPension?: Core.Models.TipoPension;
    }

    /**
     * @description Modelo de apoyo para presentar las fuentes de ingreso de una figura de apoyo
     */
    export interface RespuestaIngresoFiguraApoyo {
      /**
       * @description Estudio socioeconómico encriptado de la figura del apoyo
       */
      estudioSocioeconomicoId?: string;
      /**
       * @description Número de la figura de apoyo
       */
      numero: number;
      tipoFuenteIngreso: Core.Enums.TipoFuenteIngreso;
      actividadEconomica?: Core.Enums.ActividadEconomica;
      /**
       * @description Nombre de al empresa del tipo de fuente de ingreso de la figura de apoyo
       */
      nombreEmpresa?: string;
      /**
       * @description Identificador del giro de la empresa del tipo de fuente de ingreso de la figura de apoyo
       */
      giroEmpresa?: number;
      /**
       * @description Teléfono del tipo de fuente de ingreso de la figura de apoyo
       */
      telefono?: string;
      /**
       * @description Extensión del tipo de fuente de ingreso de la figura de apoyo
       */
      extension?: string;
      /**
       * @description Email del tipo de fuente de ingreso de la figura de apoyo
       */
      email?: string;
      /**
       * @description Puesto que desempeña del tipo de fuente de ingreso de la figura de apoyo
       */
      puesto?: string;
      /**
       * @description Antiguedad en meses del tipo de fuente de ingreso de la figura de apoyo
       */
      antiguedad?: number;
      /**
       * @description Nombre del Jefe directo del tipo de fuente de ingreso de la figura de apoyo
       */
      nombreJefe?: string;
      /**
       * @description Teléfono de jefe del tipo de fuente de ingreso de la figura de apoyo
       */
      telefonoJefe?: string;
      /**
       * @description Ingreso bruto del tipo de fuente de ingreso de la figura de apoyo
       */
      ingresoBruto?: number;
      /**
       * @description Gastos del tipo de fuente de ingreso de la figura de apoyo
       */
      impuestos?: number;
      /**
       * @description Calle del tipo de fuente de ingreso de la figura de apoyo
       */
      calle?: string;
      /**
       * @description Número Exterior del tipo de fuente de ingreso de la figura de apoyo
       */
      noExterior?: string;
      /**
       * @description Némero interior del tipo de fuente de ingreso de la figura de apoyo
       */
      noInterior?: string;
      /**
       * @description Codigo Postal del tipo de fuente de ingreso de la figura de apoyo
       */
      codigoPostal?: string;
      /**
       * @description Identificador de colonia del tipo de fuente de ingreso de la figura de apoyo
       */
      coloniaId?: number;
      /**
       * @description Municipio o Alcaldía del tipo de fuente de ingreso de la figura de apoyo
       */
      municipioAlcaldia?: string;
      /**
       * @description Booleano para saber si es socio del tipo de fuente de ingreso de la figura de apoyo
       */
      esSocio?: boolean;
      /**
       * @description Descripción del tipo de fuente de ingreso de la figura de apoyo
       */
      descripcion?: string;
      /**
       * @description Segunda Descripción del tipo de fuente de ingreso de la figura de apoyo
       */
      descripcion2?: string;
      tipoPension?: Core.Models.TipoPension;
    }

    /**
     * @description Clase para obtener el Número de Contrato Ligado a la Aclaración
     */
    export interface TablaAclaracion {
      aclaracionId: number;
      fecha: string;
      clienteId: number;
      tipoAclaracionId: number;
      detalle?: string;
      fechaTerminacion?: string;
      archivo: boolean;
      tipoAclaracion: Core.Models.TipoAclaracion;
      /**
       * @description Cadena que contiene el Número de Contrato ligado a la aclaración
       */
      numeroContrato?: string;
    }

    export enum TipoFigura {
      CoTitular = "CoTitular",
      ObligadoSolidario = "ObligadoSolidario",
      GaranteHipotecario = "GaranteHipotecario"
    }

    export interface ValidarCodigoVerificacionBody {
      codigo?: string;
      clienteId?: number;
      canal: Core.Enums.Canal;
    }

    export interface ValidarCodigoVerificacionRecuperacionBody {
      codigo?: string;
      canal: Core.Enums.Canal;
    }

    export class AdjudicacionApi extends BaseAPI {
      public ObtenerAdjudicacionesMesUrl = "/api/Cliente/Cliente/Adjudicacion/ObtenerAdjudicacionesMes";

      public async ObtenerAdjudicacionesMes(
        annio: number,
        mes: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Financiamiento.Adjudicaciones[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          Annio: annio,
          Mes: mes
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Adjudicacion/ObtenerAdjudicacionesMes",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class AtencionClienteApi extends BaseAPI {
      public ListarAclaracionesUrl = "/api/Cliente/Cliente/AtencionCliente/ListarAclaraciones";

      public async ListarAclaraciones(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Core.Models.TipoAclaracion[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/AtencionCliente/ListarAclaraciones",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarAclaracionUrl = "/api/Cliente/Cliente/AtencionCliente/GuardarAclaracion";

      public async GuardarAclaracion(
        aclaracion?: Core.Models.Aclaracion,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = aclaracion;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/AtencionCliente/GuardarAclaracion",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarUsuarioAclaracionUrl = "/api/Cliente/Cliente/AtencionCliente/GuardarUsuarioAclaracion";

      public async GuardarUsuarioAclaracion(
        aclaracionUsuario?: Core.Models.AclaracionUsuario,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = aclaracionUsuario;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/AtencionCliente/GuardarUsuarioAclaracion",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarArchivoUrl = "/api/Cliente/Cliente/AtencionCliente/GuardarArchivo";

      public async GuardarArchivo(
        archivo: Blob,
        ruta: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<string>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data = objectToFormData({
          archivo: archivo
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/AtencionCliente/GuardarArchivo",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerArchivoUrl = "/api/Cliente/Cliente/AtencionCliente/ObtenerArchivo";

      public async ObtenerArchivo(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/AtencionCliente/ObtenerArchivo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerAclaracionesUrl = "/api/Cliente/Cliente/AtencionCliente/ObtenerAclaraciones";

      public async ObtenerAclaraciones(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<TablaAclaracion[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/AtencionCliente/ObtenerAclaraciones",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public CrearZipUrl = "/api/Cliente/Cliente/AtencionCliente/CrearZip";

      public async CrearZip(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<string>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/AtencionCliente/CrearZip",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class CalculadoraApi extends BaseAPI {
      public GuardarDatosCalculadoraUrl = "/api/Cliente/Cliente/Calculadora/GuardarDatosCalculadora";

      public async GuardarDatosCalculadora(
        calculadoraBody?: Models.CalculadoraBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = calculadoraBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Calculadora/GuardarDatosCalculadora",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class ClienteApi extends BaseAPI {
      public ObtenerPersonaFisicaUrl = "/api/Cliente/Cliente/Cliente/ObtenerPersonaFisica";

      public async ObtenerPersonaFisica(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Core.Models.PersonaFisica>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Cliente/ObtenerPersonaFisica",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerPersonaMoralUrl = "/api/Cliente/Cliente/Cliente/ObtenerPersonaMoral";

      public async ObtenerPersonaMoral(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Core.Models.PersonaMoral>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Cliente/ObtenerPersonaMoral",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class ColoniaApi extends BaseAPI {
      public ObtenerColoniaUrl = "/api/Cliente/Cliente/Colonia/ObtenerColonia";

      public async ObtenerColonia(
        codigoPostal: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<ObtenerColoniasBody[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          codigoPostal: codigoPostal
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Colonia/ObtenerColonia",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class DomiciliacionApi extends BaseAPI {
      public InformacionDomiciliacionUrl = "/api/Cliente/Cliente/Domiciliacion/InformacionDomiciliacion";

      public async InformacionDomiciliacion(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<BodyDomiciliacion>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/InformacionDomiciliacion",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerBancosUrl = "/api/Cliente/Cliente/Domiciliacion/ObtenerBancos";

      public async ObtenerBancos(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<BodyBanco[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/ObtenerBancos",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDatosDomiciliacionUrl = "/api/Cliente/Cliente/Domiciliacion/GuardarDatosDomiciliacion";

      public async GuardarDatosDomiciliacion(
        bodyDomiciliacion?: BodyDomiciliacion,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = bodyDomiciliacion;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/GuardarDatosDomiciliacion",
            "PUT",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public NotificarUsuariosUrl = "/api/Cliente/Cliente/Domiciliacion/NotificarUsuarios";

      public async NotificarUsuarios(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/NotificarUsuarios",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDocumentosDomiciliacionUrl = "/api/Cliente/Cliente/Domiciliacion/GuardarDocumentosDomiciliacion";

      public async GuardarDocumentosDomiciliacion(
        archivo: Blob,
        cuentaDomiciliacionId: string,
        tipo: Core.Enums.TipoDocumentoDomiciliacion,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          CuentaDomiciliacionId: cuentaDomiciliacionId,
          tipo: tipo
        };

        const data = objectToFormData({
          archivo: archivo
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/GuardarDocumentosDomiciliacion",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDomiciliacionContratoUrl = "/api/Cliente/Cliente/Domiciliacion/GuardarDomiciliacionContrato";

      public async GuardarDomiciliacionContrato(
        cuentaDomiciliacionId: string,
        informacionFinanciamientoContrato?: Financiamiento.InformacionFinanciamientoContrato,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          CuentaDomiciliacionId: cuentaDomiciliacionId
        };

        const data = informacionFinanciamientoContrato;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/GuardarDomiciliacionContrato",
            "PUT",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentosDomiciliacionUrl = "/api/Cliente/Cliente/Domiciliacion/ObtenerDocumentosDomiciliacion";

      public async ObtenerDocumentosDomiciliacion(
        ruta: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/ObtenerDocumentosDomiciliacion",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDomiciliacionContratosUrl = "/api/Cliente/Cliente/Domiciliacion/GuardarDomiciliacionContratos";

      public async GuardarDomiciliacionContratos(
        cuentaDomiciliacionId: string,
        informacionFinanciamientoContrato?: Financiamiento.InformacionFinanciamientoContrato[],
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          CuentaDomiciliacionId: cuentaDomiciliacionId
        };

        const data = informacionFinanciamientoContrato;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/GuardarDomiciliacionContratos",
            "PUT",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ListarDomiciliacionesUrl = "/api/Cliente/Cliente/Domiciliacion/ListarDomiciliaciones";

      public async ListarDomiciliaciones(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/ListarDomiciliaciones",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarRespuestaIncodeUrl = "/api/Cliente/Cliente/Domiciliacion/GuardarRespuestaIncode";

      public async GuardarRespuestaIncode(
        onboardingIncodeBody?: OnboardingIncodeBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = onboardingIncodeBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Domiciliacion/GuardarRespuestaIncode",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class EscrituracionApi extends BaseAPI {
      public ObtenerEsrituracionSesionUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerEsrituracionSesion";

      public async ObtenerEsrituracionSesion(
        id: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<string>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          id: id
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerEsrituracionSesion",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDatosClienteUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerDatosCliente";

      public async ObtenerDatosCliente(
        id: string,
        desdeFiguraApoyo: boolean = false,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<EstudioSocioeconomico>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          id: id,
          desdeFiguraApoyo: desdeFiguraApoyo
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerDatosCliente",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public CifradoUrl = "/api/Cliente/Cliente/Escrituracion/Cifrado";

      public async Cifrado(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<string>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/Cifrado",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDatosObligadoSolidarioUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerDatosObligadoSolidario";

      public async ObtenerDatosObligadoSolidario(
        id: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<ObligadoSolidario>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          id: id
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerDatosObligadoSolidario",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerProgresoEscrituracionUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerProgresoEscrituracion";

      public async ObtenerProgresoEscrituracion(
        id: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<ProgresoEscrituracion>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          id: id
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerProgresoEscrituracion",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerProfesionesUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerProfesiones";

      public async ObtenerProfesiones(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Core.Models.Profesion[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerProfesiones",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerColoniasUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerColonias";

      public async ObtenerColonias(
        codigoPostal: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<ObtenerColoniasBody[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          codigoPostal: codigoPostal
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerColonias",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarEstudioSocioeconomicoUrl = "/api/Cliente/Cliente/Escrituracion/GuardarEstudioSocioeconomico";

      public async GuardarEstudioSocioeconomico(
        estudioSocioeconomico: EstudioSocioeconomico,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = estudioSocioeconomico;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/GuardarEstudioSocioeconomico",
            "PUT",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarEstudioSocioeconomicoObligadoSolidarioUrl =
        "/api/Cliente/Cliente/Escrituracion/GuardarEstudioSocioeconomicoObligadoSolidario";

      public async GuardarEstudioSocioeconomicoObligadoSolidario(
        obligadoSolidario: ObligadoSolidario,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = obligadoSolidario;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/GuardarEstudioSocioeconomicoObligadoSolidario",
            "PUT",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerPaisesUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerPaises";

      public async ObtenerPaises(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Core.Models.Pais[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerPaises",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerArchivoAutorizacionConsultaBuroCreditoUrl =
        "/api/Cliente/Cliente/Escrituracion/ObtenerArchivoAutorizacionConsultaBuroCredito";

      public async ObtenerArchivoAutorizacionConsultaBuroCredito(
        id: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Blob>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          id: id
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerArchivoAutorizacionConsultaBuroCredito",
            "GET",
            localVarQueryParameter,
            false,
            data,
            true,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDocumentoUrl = "/api/Cliente/Cliente/Escrituracion/GuardarDocumento";

      public async GuardarDocumento(
        archivo: Blob,
        documentoTramiteTitulacionId: number,
        obligadoSolidarioNumero: number,
        token: string,
        estudioSocioeconomicoEntidad: Core.Enums.EstudioSocioeconomicoEntidad,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = objectToFormData({
          Archivo: archivo,
          DocumentoTramiteTitulacionId: documentoTramiteTitulacionId,
          ObligadoSolidarioNumero: obligadoSolidarioNumero,
          Token: token,
          EstudioSocioeconomicoEntidad: estudioSocioeconomicoEntidad
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/GuardarDocumento",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentoUrl = "/api/Cliente/Cliente/Escrituracion/ObtenerDocumento";

      public async ObtenerDocumento(
        token: string,
        obligadoSolidarioNumero: number,
        estudioSocioeconomicoEntidad: Core.Enums.EstudioSocioeconomicoEntidad,
        documentoTramiteTitulacionId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          token: token,
          obligadoSolidarioNumero: obligadoSolidarioNumero,
          estudioSocioeconomicoEntidad: estudioSocioeconomicoEntidad,
          documentoTramiteTitulacionId: documentoTramiteTitulacionId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Escrituracion/ObtenerDocumento",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class EstadisticaApi extends BaseAPI {
      public GuardarEstaditicaAppUrl = "/api/Cliente/Cliente/Estadistica/GuardarEstaditicaApp";

      public async GuardarEstaditicaApp(
        moduloEstadistica?: Models.Estadisticas.ModuloEstadistica,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = moduloEstadistica;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Estadistica/GuardarEstaditicaApp",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class EstudioSocioeconomicoApi extends BaseAPI {
      public ObtenerUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/Obtener";

      public async Obtener(
        operacionTitulacionId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<EstudioSocioeconomicoV2>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          OperacionTitulacionId: operacionTitulacionId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/Obtener",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/Guardar";

      public async Guardar(
        estudioSocioeconomicoV2?: EstudioSocioeconomicoV2,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = estudioSocioeconomicoV2;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/Guardar",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerFigurasApoyoUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerFigurasApoyo";

      public async ObtenerFigurasApoyo(
        estudioSocioeconomicoId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<EstudioSocioeconomicoFigurasApoyoV2[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          EstudioSocioeconomicoId: estudioSocioeconomicoId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerFigurasApoyo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarFiguraApoyoUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarFiguraApoyo";

      public async GuardarFiguraApoyo(
        estudioSocioeconomicoFigurasApoyoV2?: EstudioSocioeconomicoFigurasApoyoV2,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = estudioSocioeconomicoFigurasApoyoV2;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarFiguraApoyo",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerIngresosUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerIngresos";

      public async ObtenerIngresos(
        estudioSocioeconomicoId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<RespuestaFuenteIngreso[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          EstudioSocioeconomicoId: estudioSocioeconomicoId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerIngresos",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarIngresosUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarIngresos";

      public async GuardarIngresos(
        respuestaFuenteIngreso?: RespuestaFuenteIngreso[],
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = respuestaFuenteIngreso;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarIngresos",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerIngresosFiguraApoyoUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerIngresosFiguraApoyo";

      public async ObtenerIngresosFiguraApoyo(
        estudioSocioeconomicoId: string,
        numero: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<RespuestaIngresoFiguraApoyo[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          EstudioSocioeconomicoId: estudioSocioeconomicoId,
          Numero: numero
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerIngresosFiguraApoyo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarIngresosFiguraApoyoUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarIngresosFiguraApoyo";

      public async GuardarIngresosFiguraApoyo(
        respuestaIngresoFiguraApoyo?: RespuestaIngresoFiguraApoyo[],
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = respuestaIngresoFiguraApoyo;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarIngresosFiguraApoyo",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentosUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerDocumentos";

      public async ObtenerDocumentos(
        estudioSocioeconomicoId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Perfil.DocumentoTramiteTitulacionEntidad[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          EstudioSocioeconomicoId: estudioSocioeconomicoId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerDocumentos",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentosFiguraUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerDocumentosFigura";

      public async ObtenerDocumentosFigura(
        estudioSocioeconomicoId: string,
        numero: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Perfil.DocumentoTramiteTitulacionEntidad[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          EstudioSocioeconomicoId: estudioSocioeconomicoId,
          numero: numero
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerDocumentosFigura",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDocumentoUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarDocumento";

      public async GuardarDocumento(
        archivo: Blob,
        documentoTramiteTitulacionId: number,
        obligadoSolidarioNumero: number,
        token: string,
        categoria: string,
        estudioSocioeconomicoEntidad: Core.Enums.EstudioSocioeconomicoEntidad,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = objectToFormData({
          Archivo: archivo,
          DocumentoTramiteTitulacionId: documentoTramiteTitulacionId,
          ObligadoSolidarioNumero: obligadoSolidarioNumero,
          Token: token,
          Categoria: categoria,
          EstudioSocioeconomicoEntidad: estudioSocioeconomicoEntidad
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/GuardarDocumento",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentoUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerDocumento";

      public async ObtenerDocumento(
        token: string,
        obligadoSolidarioNumero: number,
        estudioSocioeconomicoEntidad: Core.Enums.EstudioSocioeconomicoEntidad,
        documentoTramiteTitulacionId: number,
        categoria: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Blob>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          token: token,
          obligadoSolidarioNumero: obligadoSolidarioNumero,
          estudioSocioeconomicoEntidad: estudioSocioeconomicoEntidad,
          documentoTramiteTitulacionId: documentoTramiteTitulacionId,
          categoria: categoria
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/ObtenerDocumento",
            "GET",
            localVarQueryParameter,
            false,
            data,
            true,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public EliminarDocumentoUrl = "/api/Cliente/Cliente/EstudioSocioeconomico/EliminarDocumento";

      public async EliminarDocumento(
        estudioSocioEconomicoId: string,
        figura: number,
        entidad: Core.Enums.EstudioSocioeconomicoEntidad,
        documentoTramiteTitulacionId: string,
        categoria: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          estudioSocioEconomicoId: estudioSocioEconomicoId,
          figura: figura,
          entidad: entidad,
          documentoTramiteTitulacionId: documentoTramiteTitulacionId,
          categoria: categoria
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/EstudioSocioeconomico/EliminarDocumento",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class FacturacionApi extends BaseAPI {
      public ObtenerDatosFacturacionUrl = "/api/Cliente/Cliente/Facturacion/ObtenerDatosFacturacion";

      public async ObtenerDatosFacturacion(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<FacturacionBody>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Facturacion/ObtenerDatosFacturacion",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDatosFacturacionUrl = "/api/Cliente/Cliente/Facturacion/GuardarDatosFacturacion";

      public async GuardarDatosFacturacion(
        facturacionBody: FacturacionBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = facturacionBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Facturacion/GuardarDatosFacturacion",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerPDFFacturacionUrl = "/api/Cliente/Cliente/Facturacion/ObtenerPDFFacturacion";

      public async ObtenerPDFFacturacion(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Facturacion/ObtenerPDFFacturacion",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDocumentoFacturacionUrl = "/api/Cliente/Cliente/Facturacion/GuardarDocumentoFacturacion";

      public async GuardarDocumentoFacturacion(
        archivo: Blob,
        tipo: Core.Enums.TipoDocumentoDomiciliacion,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tipo: tipo
        };

        const data = objectToFormData({
          archivo: archivo
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Facturacion/GuardarDocumentoFacturacion",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public NotificarUsuariosUrl = "/api/Cliente/Cliente/Facturacion/NotificarUsuarios";

      public async NotificarUsuarios(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Facturacion/NotificarUsuarios",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class FinanciamientoApi extends BaseAPI {
      public ObtenerInformacionUrl = "/api/Cliente/Cliente/Financiamiento/ObtenerInformacion";

      public async ObtenerInformacion(
        aplicacion: Models.AplicacionMotorPagos,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Financiamiento.InformacionFinanciamiento>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          aplicacion: aplicacion
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerInformacion",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerContratosUrl = "/api/Cliente/Cliente/Financiamiento/ObtenerContratos";

      public async ObtenerContratos(
        aplicacion: Models.AplicacionMotorPagos,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Financiamiento.InformacionFinanciamientoContrato[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          aplicacion: aplicacion
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerContratos",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerPdfManualConsumidorUrl = "/api/Cliente/Cliente/Financiamiento/ObtenerPdfManualConsumidor";

      public async ObtenerPdfManualConsumidor(
        contratoId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Blob>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          contratoId: contratoId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerPdfManualConsumidor",
            "GET",
            localVarQueryParameter,
            true,
            data,
            true,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDescuentoUrl = "/api/Cliente/Cliente/Financiamiento/ObtenerDescuento";

      public async ObtenerDescuento(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<DescuentosApoyo[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerDescuento",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDescuentosUltimoMesUrl = "/api/Cliente/Cliente/Financiamiento/ObtenerDescuentosUltimoMes";

      public async ObtenerDescuentosUltimoMes(
        contratoId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          contratoId: contratoId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerDescuentosUltimoMes",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDescuentosUltimoMesTodosContratosUrl =
        "/api/Cliente/Cliente/Financiamiento/ObtenerDescuentosUltimoMesTodosContratos";

      public async ObtenerDescuentosUltimoMesTodosContratos(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerDescuentosUltimoMesTodosContratos",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ActualizarAliasGarantiaUrl = "/api/Cliente/Cliente/Financiamiento/ActualizarAliasGarantia";

      public async ActualizarAliasGarantia(
        garantiaId: number,
        alias: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          GarantiaId: garantiaId,
          Alias: alias
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ActualizarAliasGarantia",
            "PUT",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerHistoricoPagosUrl = "/api/Cliente/Cliente/Financiamiento/ObtenerHistoricoPagos";

      public async ObtenerHistoricoPagos(
        aplicacion: Models.AplicacionMotorPagos,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          aplicacion: aplicacion
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerHistoricoPagos",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerUltimosPagosUrl = "/api/Cliente/Cliente/Financiamiento/ObtenerUltimosPagos";

      public async ObtenerUltimosPagos(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Financiamiento.RespuestaUltimosPagos>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Financiamiento/ObtenerUltimosPagos",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class GiroIngresoApi extends BaseAPI {
      public ObtenerUrl = "/api/Cliente/Cliente/GiroIngreso/Obtener";

      public async Obtener(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Perfil.GiroIngresoRespuesta[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/GiroIngreso/Obtener",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class HomeApi extends BaseAPI {
      public ObtenerInformacionUrl = "/api/Cliente/Cliente/Home/ObtenerInformacion";

      public async ObtenerInformacion(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Financiamiento.InformacionCliente>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ObtenerInformacion",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ActualizarInformacionClienteUrl = "/api/Cliente/Cliente/Home/ActualizarInformacionCliente";

      public async ActualizarInformacionCliente(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ActualizarInformacionCliente",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public RegistrarUsuarioUrl = "/api/Cliente/Cliente/Home/RegistrarUsuario";

      public async RegistrarUsuario(
        registrarUsuarioBody?: Home.RegistrarUsuarioBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Home.RespuestaRegistrarUsuario>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = registrarUsuarioBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/RegistrarUsuario",
            "PUT",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public LoginUrl = "/api/Cliente/Cliente/Home/Login";

      public async Login(
        loginBody: Home.LoginBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Home.RespuestaLogin>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = loginBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/Login",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public LogoutUrl = "/api/Cliente/Cliente/Home/Logout";

      public async Logout(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/Logout",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public SolicitudCambioContraseniaUrl = "/api/Cliente/Cliente/Home/SolicitudCambioContrasenia";

      public async SolicitudCambioContrasenia(
        altaUsuario?: Core.Models.AltaUsuario,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = altaUsuario;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/SolicitudCambioContrasenia",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ActualizarContraseniaUrl = "/api/Cliente/Cliente/Home/ActualizarContrasenia";

      public async ActualizarContrasenia(
        token: string,
        actualizarAltaCliente?: ActualizarAltaCliente,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          token: token
        };

        const data = actualizarAltaCliente;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ActualizarContrasenia",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public PingUrl = "/api/Cliente/Cliente/Home/Ping";

      public async Ping(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Home.RespuestaPing>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/Ping",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerInformacionHomeUrl = "/api/Cliente/Cliente/Home/ObtenerInformacionHome";

      public async ObtenerInformacionHome(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Home.InformacionHome>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ObtenerInformacionHome",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerAdeudoVencidosUrl = "/api/Cliente/Cliente/Home/ObtenerAdeudoVencidos";

      public async ObtenerAdeudoVencidos(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ObtenerAdeudoVencidos",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerMediosContactoUrl = "/api/Cliente/Cliente/Home/ObtenerMediosContacto";

      public async ObtenerMediosContacto(
        correo: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          correo: correo
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ObtenerMediosContacto",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public AceptarTerminosCondicionesAvisoPrivacidadUrl =
        "/api/Cliente/Cliente/Home/AceptarTerminosCondicionesAvisoPrivacidad";

      public async AceptarTerminosCondicionesAvisoPrivacidad(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/AceptarTerminosCondicionesAvisoPrivacidad",
            "PATCH",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public EnviarCodigoVerificacionEmailUrl = "/api/Cliente/Cliente/Home/EnviarCodigoVerificacionEmail";

      public async EnviarCodigoVerificacionEmail(
        enviarCodigoVerificacionMailBody?: EnviarCodigoVerificacionMailBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = enviarCodigoVerificacionMailBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/EnviarCodigoVerificacionEmail",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public EnviarCodigoVerificacionPuntoContactoUrl =
        "/api/Cliente/Cliente/Home/EnviarCodigoVerificacionPuntoContacto";

      public async EnviarCodigoVerificacionPuntoContacto(
        enviarCodigoVerificacionPuntoContactoBody?: EnviarCodigoVerificacionPuntoContactoBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = enviarCodigoVerificacionPuntoContactoBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/EnviarCodigoVerificacionPuntoContacto",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public EnviarCodigoVerificacionSmsUrl = "/api/Cliente/Cliente/Home/EnviarCodigoVerificacionSms";

      public async EnviarCodigoVerificacionSms(
        enviarCodigoVerificacionSmsBody?: EnviarCodigoVerificacionSmsBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = enviarCodigoVerificacionSmsBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/EnviarCodigoVerificacionSms",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ValidarCodigoVerificacionUrl = "/api/Cliente/Cliente/Home/ValidarCodigoVerificacion";

      public async ValidarCodigoVerificacion(
        validarCodigoVerificacionBody?: ValidarCodigoVerificacionBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = validarCodigoVerificacionBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ValidarCodigoVerificacion",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ValidarCodigoVerificacionRecuperacionUrl =
        "/api/Cliente/Cliente/Home/ValidarCodigoVerificacionRecuperacion";

      public async ValidarCodigoVerificacionRecuperacion(
        validarCodigoVerificacionRecuperacionBody?: ValidarCodigoVerificacionRecuperacionBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = validarCodigoVerificacionRecuperacionBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ValidarCodigoVerificacionRecuperacion",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GenerarPINUrl = "/api/Cliente/Cliente/Home/GenerarPIN";

      public async GenerarPIN(
        generarPINBody?: Home.GenerarPINBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = generarPINBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/GenerarPIN",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ValidarPINUrl = "/api/Cliente/Cliente/Home/ValidarPIN";

      public async ValidarPIN(
        valor: string,
        medioContacto: number,
        code: string,
        canal: Core.Enums.Canal,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          valor: valor,
          medioContacto: medioContacto,
          code: code,
          canal: canal
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ValidarPIN",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public CambiarContraseniaTokenUrl = "/api/Cliente/Cliente/Home/CambiarContraseniaToken";

      public async CambiarContraseniaToken(
        canal: Core.Enums.Canal,
        cambiarContraseniaTokenBody?: Home.CambiarContraseniaTokenBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          canal: canal
        };

        const data = cambiarContraseniaTokenBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/CambiarContraseniaToken",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarIdentificacionUrl = "/api/Cliente/Cliente/Home/GuardarIdentificacion";

      public async GuardarIdentificacion(
        identificacion: Blob,
        flag: boolean,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          flag: flag
        };

        const data = objectToFormData({
          identificacion: identificacion
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/GuardarIdentificacion",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ActualizarDatosClienteUrl = "/api/Cliente/Cliente/Home/ActualizarDatosCliente";

      public async ActualizarDatosCliente(
        telParticular: string,
        celParticular: string,
        correoPersonal: string,
        calle: string,
        numero: string,
        codigoPostal: string,
        ladaPais: number,
        coloniaId: number,
        canal: Core.Enums.Canal,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          TelParticular: telParticular,
          CelParticular: celParticular,
          correoPersonal: correoPersonal,
          calle: calle,
          Numero: numero,
          CodigoPostal: codigoPostal,
          LadaPais: ladaPais,
          ColoniaId: coloniaId,
          canal: canal
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ActualizarDatosCliente",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerZipUrl = "/api/Cliente/Cliente/Home/ObtenerZip";

      public async ObtenerZip(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ObtenerZip",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public VerDocumentoUrl = "/api/Cliente/Cliente/Home/VerDocumento";

      public async VerDocumento(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/VerDocumento",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ListarPaisesUrl = "/api/Cliente/Cliente/Home/ListarPaises";

      public async ListarPaises(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Core.Models.Pais[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ListarPaises",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerArchivoPublicoUrl = "/api/Cliente/Cliente/Home/ObtenerArchivoPublico";

      public async ObtenerArchivoPublico(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ObtenerArchivoPublico",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ActualizarBeneficiarioUrl = "/api/Cliente/Cliente/Home/ActualizarBeneficiario";

      public async ActualizarBeneficiario(
        beneficiarioDetalle?: BeneficiarioDetalle[],
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = beneficiarioDetalle;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ActualizarBeneficiario",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public EliminarBeneficiarioUrl = "/api/Cliente/Cliente/Home/EliminarBeneficiario";

      public async EliminarBeneficiario(
        beneficiarioId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          beneficiarioId: beneficiarioId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/EliminarBeneficiario",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public AvisoPoliticasUrl = "/api/Cliente/Cliente/Home/AvisoPoliticas";

      public async AvisoPoliticas(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/AvisoPoliticas",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public AvisoPrivacidadUrl = "/api/Cliente/Cliente/Home/AvisoPrivacidad";

      public async AvisoPrivacidad(
        tipo: Enums.Aplicacion,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tipo: tipo
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/AvisoPrivacidad",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerImagenUrl = "/api/Cliente/Cliente/Home/ObtenerImagen";

      public async ObtenerImagen(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ObtenerImagen",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarImagenUrl = "/api/Cliente/Cliente/Home/GuardarImagen";

      public async GuardarImagen(imagen: Blob, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = objectToFormData({
          imagen: imagen
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/GuardarImagen",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ListarMaterialApoyoUrl = "/api/Cliente/Cliente/Home/ListarMaterialApoyo";

      public async ListarMaterialApoyo(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<MaterialApoyo[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/ListarMaterialApoyo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarSuscripcionClienteUrl = "/api/Cliente/Cliente/Home/GuardarSuscripcionCliente";

      public async GuardarSuscripcionCliente(
        token: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          token: token
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/GuardarSuscripcionCliente",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public RealizarNotificacionesUrl = "/api/Cliente/Cliente/Home/RealizarNotificaciones";

      public async RealizarNotificaciones(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/RealizarNotificaciones",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public SesionUrl = "/api/Cliente/Cliente/Home/Sesion";

      public async Sesion(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Home/Sesion",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class MaterialApoyoApi extends BaseAPI {
      public ObtenerMaterialApoyoUrl = "/api/Cliente/Cliente/MaterialApoyo/ObtenerMaterialApoyo";

      public async ObtenerMaterialApoyo(
        tipo: ObtenerMaterialApoyo,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Core.Models.MaterialApoyo[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tipo: tipo
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/MaterialApoyo/ObtenerMaterialApoyo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public BuscarMaterialApoyoUrl = "/api/Cliente/Cliente/MaterialApoyo/BuscarMaterialApoyo";

      public async BuscarMaterialApoyo(
        cadena: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Core.Models.MaterialApoyo[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          Cadena: cadena
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/MaterialApoyo/BuscarMaterialApoyo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class OperacionTitulacionApi extends BaseAPI {
      public ObtenerNombreAsesorTitulacionUrl =
        "/api/Cliente/Cliente/OperacionTitulacion/ObtenerNombreAsesorTitulacion";

      public async ObtenerNombreAsesorTitulacion(
        operacionTitulacionId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<string>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          operacionTitulacionId: operacionTitulacionId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/OperacionTitulacion/ObtenerNombreAsesorTitulacion",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerOperacionesActivasUrl = "/api/Cliente/Cliente/OperacionTitulacion/ObtenerOperacionesActivas";

      public async ObtenerOperacionesActivas(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Perfil.OperacionTitulacionRespuesta[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/OperacionTitulacion/ObtenerOperacionesActivas",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerEtapaOperacionTitulacionUrl =
        "/api/Cliente/Cliente/OperacionTitulacion/ObtenerEtapaOperacionTitulacion";

      public async ObtenerEtapaOperacionTitulacion(
        operacionTitulacionId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Perfil.OperacionTitulacionRespuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          operacionTitulacionId: operacionTitulacionId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/OperacionTitulacion/ObtenerEtapaOperacionTitulacion",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class PagoApi extends BaseAPI {
      public CrearCodiUrl = "/api/Cliente/Cliente/Pago/CrearCodi";

      public async CrearCodi(
        telefono: string,
        monto: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          telefono: telefono,
          monto: monto
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Pago/CrearCodi",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerPosibilidadPagoUrl = "/api/Cliente/Cliente/Pago/ObtenerPosibilidadPago";

      public async ObtenerPosibilidadPago(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Pago/ObtenerPosibilidadPago",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public TienePagoPendientePorAplicarUrl = "/api/Cliente/Cliente/Pago/TienePagoPendientePorAplicar";

      public async TienePagoPendientePorAplicar(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Pago/TienePagoPendientePorAplicar",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class ProfesionApi extends BaseAPI {
      public ObtenerProfesionesUrl = "/api/Cliente/Cliente/Profesion/ObtenerProfesiones";

      public async ObtenerProfesiones(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Core.Models.Profesion[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Profesion/ObtenerProfesiones",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class ProgramaReciclaApi extends BaseAPI {
      public ObtenerClienteProgramaReciclaUrl = "/api/Cliente/Cliente/ProgramaRecicla/ObtenerClienteProgramaRecicla";

      public async ObtenerClienteProgramaRecicla(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/ProgramaRecicla/ObtenerClienteProgramaRecicla",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ActivarProgramaReciclaUrl = "/api/Cliente/Cliente/ProgramaRecicla/ActivarProgramaRecicla";

      public async ActivarProgramaRecicla(
        programaRecicla: boolean,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ProgramaRecicla: programaRecicla
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/ProgramaRecicla/ActivarProgramaRecicla",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public LoginConSesionUrl = "/api/Cliente/Cliente/ProgramaRecicla/LoginConSesion";

      public async LoginConSesion(password: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          password: password
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/ProgramaRecicla/LoginConSesion",
            "POST",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class RegimenFiscalApi extends BaseAPI {
      public ObtenerUrl = "/api/Cliente/Cliente/RegimenFiscal/Obtener";

      public async Obtener(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Core.Models.RegimenFiscal[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/RegimenFiscal/Obtener",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class SeguroApi extends BaseAPI {
      public GuardarArchivoUrl = "/api/Cliente/Cliente/Seguro/GuardarArchivo";

      public async GuardarArchivo(
        archivo: Blob,
        ruta: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<string>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data = objectToFormData({
          archivo: archivo
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/GuardarArchivo",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public BorrarArchivoUrl = "/api/Cliente/Cliente/Seguro/BorrarArchivo";

      public async BorrarArchivo(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<boolean>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/BorrarArchivo",
            "DELETE",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerArchivoUrl = "/api/Cliente/Cliente/Seguro/ObtenerArchivo";

      public async ObtenerArchivo(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerArchivo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerPagoPrimaUrl = "/api/Cliente/Cliente/Seguro/ObtenerPagoPrima";

      public async ObtenerPagoPrima(
        tramiteSeguroId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<PrimaMensual>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          TramiteSeguroId: tramiteSeguroId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerPagoPrima",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerLineaVidaSeguroUrl = "/api/Cliente/Cliente/Seguro/ObtenerLineaVidaSeguro";

      public async ObtenerLineaVidaSeguro(
        tramiteSeguroId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<ProgresoSeguroVida>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          TramiteSeguroId: tramiteSeguroId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerLineaVidaSeguro",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerBeneficiarioDetalleUrl = "/api/Cliente/Cliente/Seguro/ObtenerBeneficiarioDetalle";

      public async ObtenerBeneficiarioDetalle(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerBeneficiarioDetalle",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerTramiteSeguroUrl = "/api/Cliente/Cliente/Seguro/ObtenerTramiteSeguro";

      public async ObtenerTramiteSeguro(
        cotitularID: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Seguro.TramiteSeguro[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          CotitularID: cotitularID
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerTramiteSeguro",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerCotitularesUrl = "/api/Cliente/Cliente/Seguro/ObtenerCotitulares";

      public async ObtenerCotitulares(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Core.Models.PersonaFisica[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerCotitulares",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerConsentimientoMedicoUrl = "/api/Cliente/Cliente/Seguro/ObtenerConsentimientoMedico";

      public async ObtenerConsentimientoMedico(opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Blob>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerConsentimientoMedico",
            "GET",
            localVarQueryParameter,
            true,
            data,
            true,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentoUrl = "/api/Cliente/Cliente/Seguro/ObtenerDocumento";

      public async ObtenerDocumento(
        personaFisicaId: string,
        tipoDocumento: Core.Models.TipoDocumentoTramiteSeguro,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Blob>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          personaFisicaId: personaFisicaId,
          tipoDocumento: tipoDocumento
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerDocumento",
            "GET",
            localVarQueryParameter,
            true,
            data,
            true,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerListaDocumentosUrl = "/api/Cliente/Cliente/Seguro/ObtenerListaDocumentos";

      public async ObtenerListaDocumentos(
        tramiteSeguroId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Seguro.DocumentoSeguro[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tramiteSeguroId: tramiteSeguroId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerListaDocumentos",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentoAnexoUrl = "/api/Cliente/Cliente/Seguro/ObtenerDocumentoAnexo";

      public async ObtenerDocumentoAnexo(
        tramiteSeguroId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Seguro.DocumentoSeguro[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          TramiteSeguroId: tramiteSeguroId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerDocumentoAnexo",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerArchivoAnexoUrl = "/api/Cliente/Cliente/Seguro/ObtenerArchivoAnexo";

      public async ObtenerArchivoAnexo(token: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<Blob>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          token: token
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ObtenerArchivoAnexo",
            "GET",
            localVarQueryParameter,
            true,
            data,
            true,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public AgruparGarantiasUrl = "/api/Cliente/Cliente/Seguro/AgruparGarantias";

      public async AgruparGarantias(
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Financiamiento.GarantiaAdministradaDetalle[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/AgruparGarantias",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ContratosTramiteSeguroUrl = "/api/Cliente/Cliente/Seguro/ContratosTramiteSeguro";

      public async ContratosTramiteSeguro(
        tramiteSeguroId: number,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Core.Models.vwContratos[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tramiteSeguroId: tramiteSeguroId
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Seguro/ContratosTramiteSeguro",
            "GET",
            localVarQueryParameter,
            true,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }

    export class SiniestroApi extends BaseAPI {
      public ObtenerlistaSiniestrosUrl = "/api/Cliente/Cliente/Siniestro/ObtenerlistaSiniestros";

      public async ObtenerlistaSiniestros(
        id: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Siniestro.ListaSiniestroconDocumentos[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          id: id
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/ObtenerlistaSiniestros",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerSiniestrosClienteUrl = "/api/Cliente/Cliente/Siniestro/ObtenerSiniestrosCliente";

      public async ObtenerSiniestrosCliente(
        id: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Siniestro.ListaSiniestroconDocumentos[]>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          id: id
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/ObtenerSiniestrosCliente",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerDocumentosSiniestrosUrl = "/api/Cliente/Cliente/Siniestro/ObtenerDocumentosSiniestros";

      public async ObtenerDocumentosSiniestros(
        ruta: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/ObtenerDocumentosSiniestros",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public GuardarDocumentoSiniestroUrl = "/api/Cliente/Cliente/Siniestro/GuardarDocumentoSiniestro";

      public async GuardarDocumentoSiniestro(
        archivo: Blob,
        documentoTramiteSiniestroId: string,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          DocumentoTramiteSiniestroId: documentoTramiteSiniestroId
        };

        const data = objectToFormData({
          archivo: archivo
        });
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/GuardarDocumentoSiniestro",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public NotificarCambiosUrl = "/api/Cliente/Cliente/Siniestro/NotificarCambios";

      public async NotificarCambios(
        documentoSiniestroId: string,
        siniestrosDañosPortalCliente?: Siniestro.SiniestrosDañosPortalCliente,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          DocumentoSiniestroId: documentoSiniestroId
        };

        const data = siniestrosDañosPortalCliente;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/NotificarCambios",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public CrearSiniestroUrl = "/api/Cliente/Cliente/Siniestro/CrearSiniestro";

      public async CrearSiniestro(
        tipo: Core.Enums.TipoSinestro,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          tipo: tipo
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/CrearSiniestro",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public ObtenerZipUrl = "/api/Cliente/Cliente/Siniestro/ObtenerZip";

      public async ObtenerZip(ruta: string, opcionesAdicionalesAxios?: any): Promise<AxiosResponse<void>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter = {
          ruta: ruta
        };

        const data: any = undefined;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/ObtenerZip",
            "GET",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }

      public EnviarCorreoSiniestroVidaUrl = "/api/Cliente/Cliente/Siniestro/EnviarCorreoSiniestroVida";

      public async EnviarCorreoSiniestroVida(
        enviarCorreoSiniestroVidaBody?: Siniestro.EnviarCorreoSiniestroVidaBody,
        opcionesAdicionalesAxios?: any
      ): Promise<AxiosResponse<Respuesta>> {
        if (opcionesAdicionalesAxios == undefined) opcionesAdicionalesAxios = {};
        const localVarQueryParameter: any = undefined;
        const data = enviarCorreoSiniestroVidaBody;
        return ApiFp(this.configuration)
          .CrearSolicitador(
            "/api/Cliente/Cliente/Siniestro/EnviarCorreoSiniestroVida",
            "POST",
            localVarQueryParameter,
            false,
            data,
            false,
            opcionesAdicionalesAxios
          )
          .then(request => request(this.axios, this.basePath));
      }
    }
  }

  export namespace Financiamiento {
    /**
     * @description Clase Detalle de Adjudicaciones
     */
    export interface Adjudicaciones {
      /**
       * @description Identificador del Contrato
       */
      contratoId: number;
      /**
       * @description Numero de Contraro Unico
       */
      numeroCompleto?: string;
      /**
       * @description Nombre Completo del cliente
       */
      nombreCompletoCliente?: string;
      /**
       * @description fecha de cuadno adjudico
       */
      fechaAdjudicacion?: string;
      /**
       * @description monto de contrato
       */
      montoContratado: number;
      /**
       * @description aque grupo permtenece el  contrato
       */
      numeroGrupo: number;
      /**
       * @description numero de coutas pagadas
       */
      numeroCuotaPagadaIngresoListaEspera: number;
      tipoAdjudicacion: TipoAdjudicacion;
    }

    /**
     * @description Clase customizada Contrato Domiciliado
     */
    export interface ContratoDomiciliado {
      /**
       * @description Identificador de Tabla Cifrado
       */
      cuentaDomiciliacionContratoId?: string;
      cuentaDomiciliacionId?: string;
      /**
       * @description Nombre de aquien se Domicilio
       */
      nombreDomiciliado?: string;
      /**
       * @description Clave interbancaria
       */
      clave?: string;
      /**
       * @description Telefono de quien Domicilia
       */
      telefono?: string;
      contratos: string[];
    }

    /**
     * @description Garantia con informacion para Vista de Seguro de Daños
     */
    export interface GarantiaAdministradaDetalle {
      garantiaAdministradaId: number;
      clienteId: number;
      cotitularId?: number;
      notarioId?: number;
      testimonioId?: number;
      descripcion?: string;
      calle?: string;
      numeroExterior?: string;
      numeroInterior?: string;
      codigoPostalCampoAbierto?: string;
      coloniaCampoAbierto?: string;
      coloniaId?: number;
      ciudadId: number;
      estadoId: number;
      productoContratado: Core.Models.ProductoContratado;
      tipoProducto: Core.Models.TipoProductoGarantia;
      valorGarantia: number;
      valorGarantiaActualizado: number;
      valorTotalContratos: number;
      fechaAvaluo?: string;
      certificadoDanios: boolean;
      calculaSeguroDanios: boolean;
      polizaAplicar: Core.Models.TipoPoliza;
      valorAsegurado: number;
      entregaArchivo: boolean;
      numeroEscritura?: string;
      activo: boolean;
      fechaUltimaActualizacion?: string;
      referenciaUnica?: string;
      modeloEscritura: number;
      alias?: string;
      notario: Core.Models.Notario;
      cliente: Core.Models.Cliente;
      operaciones?: Core.Models.Operacion[];
      contratos?: Core.Models.ContratoGarantia[];
      garantiaAdministradaDocumentos?: Core.Models.GarantiaAdministradaDocumento[];
      colonia: Core.Models.Colonia;
      /**
       * @description Porcentaje de Seguro de Daño
       */
      porcentaje: number;
      /**
       * @description Monto a pagar del Seguro de Daños
       */
      monto: number;
      /**
       * @description Nombre de la aseguradora
       */
      aseguradora?: string;
      /**
       * @description URL del Ceritifcado de Seguro de Daños
       */
      ruta?: string;
    }

    /**
     * @description Informacion para Recuperar Informacion Basica del Cliente
     */
    export interface InformacionCliente {
      /**
       * @description Identificador del Cliente
       */
      clienteId: number;
      /**
       * @description Nombre completo del cliente
       */
      nombreCompleto?: string;
      /**
       * @description Nombre del Cliente
       */
      nombre?: string;
      /**
       * @description Apellido paterno del Cliente
       */
      apellidoPaterno?: string;
      /**
       * @description Apellidos Cliente
       */
      apellidoMaterno?: string;
      /**
       * @description Correo del Cliente
       */
      correo?: string;
      /**
       * @description URL para Agendar Cita
       */
      urlDevQ?: string;
      /**
       * @description URL para comunicacion via WhatsApp
       */
      urlWhatsApp?: string;
      cliente: Core.Models.Cliente;
    }

    /**
     * @description Detalle sobre el financiamiento
     */
    export interface InformacionFinanciamiento {
      /**
       * @description Booleano para Saber si tiene complemento de Cuenta
       */
      tieneComplementoEstadoCuenta: boolean;
      /**
       * @description mostrar mes actual
       */
      mostrarMesActual: boolean;
      /**
       * @description Cantidad a pagar
       */
      totalPagar?: number;
      /**
       * @description Cantidad a pagar vencida
       */
      totalPagarVencido?: number;
      /**
       * @description URL para descargar pdf e complemento
       */
      linkDescargaComplemento?: string;
      /**
       * @description Referencia única del cliente para el pago de sus contratos
       */
      referenciaUnica?: string;
      /**
       * @description Fecha de Registro en Sietema
       */
      fechaSistema?: string;
      /**
       * @description Fecha de Vigencia de Contraro
       */
      fechaVigencia?: string;
      /**
       * @description Saber si el cliente en alguno de sus contratos tiene descuentos
       */
      tieneDescuentos: boolean;
    }

    /**
     * @description Detalle de financiamineto por contrato
     */
    export interface InformacionFinanciamientoContrato {
      /**
       * @description Identificador de contrato
       */
      contratoId: number;
      /**
       * @description numero de contrato
       */
      numeroContrato?: string;
      /**
       * @description Referencia unica de contrato
       */
      referenciaUnica?: string;
      /**
       * @description Fondeo a la que pertenece el contrato
       */
      fondeo?: number;
      /**
       * @description total de pagar por contrato
       */
      totalPagar?: number;
      /**
       * @description saber si el último recibo del contrato está vencido
       */
      vencido: boolean;
      /**
       * @description grupo a que pertence el contrato
       */
      grupo?: string;
      /**
       * @description etapa en la que se encuentra el contrato
       */
      etapa?: string;
      /**
       * @description Garantia del contrato
       */
      garantia: number;
      productoContratado?: Core.Models.ProductoContratado;
      /**
       * @description Alias del inmueble garantía establecido por el cliente
       */
      aliasGarantia?: string;
      /**
       * @description URL para Manual de Grupo
       */
      linkManualGrupo?: string;
      /**
       * @description URL para contrato de grupo
       */
      linkContratoGrupo?: string;
      linkEstadoCuentaContrato: string[];
      /**
       * @description Cantidad de pagos realizados
       */
      numeroCuotaVigente: number;
      /**
       * @description Cantidad de NumeroCuotaVencida
       */
      numeroCuotaVencida: number;
      /**
       * @description Cantidad de NumeroCuotaAnticipada
       */
      numeroCuotaAnticipada: number;
      /**
       * @description Cantidad de NumeroCuotaPendiente
       */
      numeroCuotaPendiente: number;
      /**
       * @description Cantidad de NumeroCuotaPendiente
       */
      totalCuotas: number;
      /**
       * @description Valor de funcion de tabla de Monto de Couta Vigente del contrato
       */
      montoCoutaVigente: number;
      esDomiciliado?: TipoDomiciliacion;
      /**
       * @description Cuota que tiene un contrato
       */
      cuotaAdeudo: number;
      /**
       * @description Fecha de vigencia
       */
      fechaVigencia?: string;
      contratoDomiciliado: ContratoDomiciliado;
    }

    export interface Pago {
      /**
       * @description Fecha del pago.
       */
      fecha: string;
      /**
       * @description Numero de contrato al que se realizó el pago.
       */
      numContrato?: string;
      /**
       * @description Monto del pago realizado.
       */
      monto: number;
    }

    export interface RespuestaUltimosPagos {
      estado: EstadoRespuesta;
      /**
       * @description Mensaje que se proporcionara para notificar estado
       */
      mensaje?: string;
      /**
       * @description Mensaje que se proporcionara para notificar estado
       */
      pagos?: Pago[];
    }

    export enum TipoAdjudicacion {
      DIRECTO = "DIRECTO",
      NATURAL = "NATURAL",
      SUBASTA = "SUBASTA",
      LISTA_DE_ESPERA = "LISTA_DE_ESPERA",
      OBLIGATORIA = "OBLIGATORIA",
      NATURAL_SIN_LISTA_ESPERA = "NATURAL_SIN_LISTA_ESPERA",
      LIQUIDACIÓN = "LIQUIDACIÓN",
      POR_LIQUIDACIÓN = "POR_LIQUIDACIÓN",
      SIN_LISTA_ESPERA = "SIN_LISTA_ESPERA"
    }

    export enum TipoDomiciliacion {
      Domiciliado = "Domiciliado",
      Proceso = "Proceso"
    }
  }

  export namespace Perfil {
    export interface DocumentosTramiteTitulacionCategoria {
      categoria?: string;
      documentos?: FormatoDocumentoJson[];
    }

    export interface DocumentoTramiteTitulacionEntidad {
      entidad?: string;
      documentosPorCategoria?: DocumentosTramiteTitulacionCategoria[];
    }

    export interface FormatoDocumentoJson {
      documentoTramiteTitulacionId: number;
      nombre?: string;
      guardado: boolean;
      /**
       * @description 0 En Revisión 1 Aceptado 2 Rechazado 3 No necesario 4 Solicitado
       */
      estatus?: number;
      comentario?: string;
      created_at: string;
      archivo?: string;
      linkArchivo?: string;
      tipoArchivo?: string;
    }

    /**
     * @description Objeto respuesta de método obtener
     */
    export interface GiroIngresoRespuesta {
      giroId: number;
      giro?: string;
    }

    /**
     * @description Respuesta de operacion titulación
     */
    export interface OperacionTitulacionRespuesta {
      operacionTitulacionId?: string;
      etapaTitulacion: Core.Models.EtapaOperacionTitulacion;
    }
  }

  export namespace Models {
    export namespace Estadisticas {
      export enum ModuloEstadistica {
        PAGOS = "PAGOS",
        CALCULADORA = "CALCULADORA",
        DOMICILIACION = "DOMICILIACION",
        ESTADO_CUENTA = "ESTADO_CUENTA",
        LOGIN = "LOGIN"
      }
    }

    export enum AplicacionMotorPagos {
      PORTAL_CLIENTE = "PORTAL_CLIENTE",
      APLICACION_MOVIL_CLIENTE = "APLICACION_MOVIL_CLIENTE"
    }

    /**
     * @description Campañas del Banner
     */
    export interface BannerCampania {
      /**
       * @description ID de la campaña
       */
      bannerCampaniaId: number;
      /**
       * @description Nombre de la campaña
       */
      nombre?: string;
      /**
       * @description URL de la imagen que se mostrará para el banner. Debe tener una dimensiones de 600x250 máximo
       */
      bannerUrl?: string;
      /**
       * @description URL que se abrirá al darle click al banner
       */
      linkUrl?: string;
      /**
       * @description Fecha de inicio de la campaña
       */
      fechaInicio: string;
      /**
       * @description Fecha de finalización de la campaña, puede ser nula para no tener fin
       */
      fechaFin?: string;
      /**
       * @description Prioridad u orden en el que mostrará el banner cuando un cliente tiene más de un banner
       */
      prioridad: number;
      /**
       * @description Clientes de la campaña
       */
      clientes?: BannerCampaniaCliente[];
    }

    /**
     * @description Clientes que pertenecen a una campaña
     */
    export interface BannerCampaniaCliente {
      /**
       * @description ID de la campaña
       */
      bannerCampaniaId: number;
      /**
       * @description ID del cliente
       */
      clienteId: number;
      bannerCampania: BannerCampania;
    }

    export interface CalculadoraBody {
      nombreCompleto?: string;
      telefono?: string;
      email?: string;
      cp?: string;
      montoContratar: number;
      ingresoMensual: number;
      medioPreferidoContacto: MedioPreferidoContacto;
      tipoAdquisicion: TipoAdquisicion;
    }

    export enum MedioPreferidoContacto {
      TELEFONO = "TELEFONO",
      CORREO = "CORREO",
      WHATSAPP = "WHATSAPP"
    }

    export enum TipoAdquisicion {
      COMPRAR = "COMPRAR",
      CONSTRUIR = "CONSTRUIR",
      REMODELAR = "REMODELAR"
    }
  }
}

export namespace Core {
  export namespace Enums {
    export enum ActividadEconomica {
      EMPRESA_PRIVADA = "EMPRESA_PRIVADA",
      EMPRESA_FAMILIAR = "EMPRESA_FAMILIAR",
      GOBIERNO = "GOBIERNO",
      PROFESIONISTA_INDEPENDIENTE = "PROFESIONISTA_INDEPENDIENTE",
      COMERCIO = "COMERCIO",
      SOCIO_O_DUEÑO = "SOCIO_O_DUEÑO"
    }

    export enum Canal {
      App_Movil = "App_Movil",
      Portal_Del_Cliente = "Portal_Del_Cliente"
    }

    export enum CategoriaDocumentoTramiteTitulacion {
      COMPROBANTE_DE_IDENTFICACION_PERSONA_FISICA = "COMPROBANTE_DE_IDENTFICACION_PERSONA_FISICA",
      DOCUMENTOS_GENERALES_DE_PERSONA_FISICA = "DOCUMENTOS_GENERALES_DE_PERSONA_FISICA",
      DOCUMENTOS_DEL_INMUEBLE = "DOCUMENTOS_DEL_INMUEBLE",
      COMPROBANTE_DE_INGRESOS = "COMPROBANTE_DE_INGRESOS",
      BURO_DE_CREDITO = "BURO_DE_CREDITO",
      FORMATO_INTERNO = "FORMATO_INTERNO",
      COMPROBANTE_DE_PAGO = "COMPROBANTE_DE_PAGO",
      DOCUMENTOS_GENERALES_DE_PERSONA_MORAL = "DOCUMENTOS_GENERALES_DE_PERSONA_MORAL",
      OTROS = "OTROS"
    }

    export enum CategoriasDeposito {
      NORMAL = "NORMAL",
      DOMICILIACION = "DOMICILIACION",
      REVERSO_BANCARIO = "REVERSO_BANCARIO",
      TRASPASO_MISMO_GRUPO = "TRASPASO_MISMO_GRUPO",
      TRASPASO_DIFERENTE_GRUPO = "TRASPASO_DIFERENTE_GRUPO",
      TRASPASO_MISMO_GRUPO_CON_DESCUENTO = "TRASPASO_MISMO_GRUPO_CON_DESCUENTO",
      TRASPASO_DIFERENTE_GRUPO_CON_DESCUENTO = "TRASPASO_DIFERENTE_GRUPO_CON_DESCUENTO",
      DISMINUCION = "DISMINUCION",
      INCREMENTO = "INCREMENTO",
      CANCELACION = "CANCELACION",
      DOMICILIACION_CON_DESCUENTO_C_ADMON = "DOMICILIACION_CON_DESCUENTO_C_ADMON",
      CUENTAS_INVEX = "CUENTAS_INVEX",
      DOMICILIACIÓN_INVEX = "DOMICILIACIÓN_INVEX",
      CUENTAS_INVEX_II = "CUENTAS_INVEX_II",
      DOMICILIACIÓN_INVEX_II = "DOMICILIACIÓN_INVEX_II",
      CUENTAS_INVEX_III = "CUENTAS_INVEX_III",
      DOMICILIACIÓN_INVEX_III = "DOMICILIACIÓN_INVEX_III",
      DOMICILIACION_INVEX_CON_DESCUENTO_C_ADMON = "DOMICILIACION_INVEX_CON_DESCUENTO_C_ADMON",
      DOMICILIACION_INVEX_II_CON_DESCUENTO_C_ADMON = "DOMICILIACION_INVEX_II_CON_DESCUENTO_C_ADMON",
      DOMICILIACION_INVEX_III_CON_DESCUENTO_C_ADMON = "DOMICILIACION_INVEX_III_CON_DESCUENTO_C_ADMON"
    }

    export enum ConceptoTransaccion {
      APORTACIÓN = "APORTACIÓN",
      ANTICIPO = "ANTICIPO",
      CUOTA_DE_INSCRIPCIÓN = "CUOTA_DE_INSCRIPCIÓN",
      SEGUNDA_CUOTA_DE_INSCRIPCIÓN = "SEGUNDA_CUOTA_DE_INSCRIPCIÓN",
      GASTOS_DE_COBRANZA = "GASTOS_DE_COBRANZA",
      MORATORIOS = "MORATORIOS",
      CESIÓN_DE_DERECHOS = "CESIÓN_DE_DERECHOS",
      INSCRIPCIÓN_POR_INCREMENTO = "INSCRIPCIÓN_POR_INCREMENTO",
      CANCELACIÓN_APORTACION = "CANCELACIÓN_APORTACION",
      CANCELACIÓN_INSCRIPCIÓN = "CANCELACIÓN_INSCRIPCIÓN",
      APORTACIÓN_DISMINUCIÓN = "APORTACIÓN_DISMINUCIÓN",
      APORTACIÓN_POR_TRASPASO = "APORTACIÓN_POR_TRASPASO",
      CANCELACIÓN = "CANCELACIÓN",
      CANCELAPAGO = "CANCELAPAGO",
      CHEQUEDEVUELTO = "CHEQUEDEVUELTO",
      DISMINUCION = "DISMINUCION",
      FUSION = "FUSION",
      GASTOSDIVERSOS = "GASTOSDIVERSOS",
      REESTRUCTURA = "REESTRUCTURA",
      RESCISION = "RESCISION",
      REVERSO = "REVERSO",
      TRASPASO = "TRASPASO",
      INSCRIPCIÓN_POR_DISMINUCION = "INSCRIPCIÓN_POR_DISMINUCION",
      ANTICIPO_POR_FUSIÓN = "ANTICIPO_POR_FUSIÓN",
      ANTICIPO_POR_TRASPASO = "ANTICIPO_POR_TRASPASO",
      ANTICIPO_POR_DOMICILIACIÓN = "ANTICIPO_POR_DOMICILIACIÓN",
      ANTICIPO_POR_REESTRUCTURA = "ANTICIPO_POR_REESTRUCTURA",
      APORTACIÓN_POR_DOMICILIACIÓN = "APORTACIÓN_POR_DOMICILIACIÓN",
      APORTACIÓN_POR_INCREMENTO = "APORTACIÓN_POR_INCREMENTO",
      APORTACIÓN_POR_FUSIÓN = "APORTACIÓN_POR_FUSIÓN",
      APORTACIÓN_POR_REESTRUCTURA = "APORTACIÓN_POR_REESTRUCTURA",
      ANTICIPO_POR_DISMINUCIÓN = "ANTICIPO_POR_DISMINUCIÓN",
      APORTACIÓN_POR_DISMINUCIÓN = "APORTACIÓN_POR_DISMINUCIÓN",
      APORTACIÓN_ANTICIPADA_HIRAUTO = "APORTACIÓN_ANTICIPADA_HIRAUTO",
      APORTACIÓN_ANTICIPADA_HIRCASAFIJO = "APORTACIÓN_ANTICIPADA_HIRCASAFIJO",
      PRÉSTAMO_BPR = "PRÉSTAMO_BPR"
    }

    export enum CondicionLadrillo {
      SIN_LADRILLOS = "SIN_LADRILLOS",
      CUOTA_PERIODICA_PUNTUAL = "CUOTA_PERIODICA_PUNTUAL",
      CUOTA_PERIODICA_ADELANTADA = "CUOTA_PERIODICA_ADELANTADA",
      CUOTA_ANTICIPADA = "CUOTA_ANTICIPADA",
      CONTRATACION_1 = "CONTRATACION_1",
      CONTRATACION_1_PRIMER_CUOTA_PUNTUAL = "CONTRATACION_1_PRIMER_CUOTA_PUNTUAL",
      CONTRATACION_1_SEGUNDA_CUOTA_PUNTUAL = "CONTRATACION_1_SEGUNDA_CUOTA_PUNTUAL",
      RECONTRATACIÓN = "RECONTRATACIÓN",
      CONTRATACION2_SEIS_CUOTAS_PUNTUALES = "CONTRATACION2_SEIS_CUOTAS_PUNTUALES",
      GANADOR = "GANADOR"
    }

    export enum EstatusAlta {
      Activo = "Activo",
      Inactivo = "Inactivo",
      Recuperacion_Automatica = "Recuperacion_Automatica"
    }

    export enum EstatusDeposito {
      POR_APLICAR = "POR_APLICAR",
      APLICADO = "APLICADO",
      NO_IDENTIFICADO = "NO_IDENTIFICADO",
      NO_APLICADO = "NO_APLICADO",
      REVERSO_BANCARIO = "REVERSO_BANCARIO",
      FUSION_TRASPASO = "FUSION_TRASPASO",
      FUSION_TRASPASO_CON_DESCUENTO = "FUSION_TRASPASO_CON_DESCUENTO",
      REVERSO_COMISIÓN_NO_APLICADO = "REVERSO_COMISIÓN_NO_APLICADO"
    }

    export enum EstatusRecibo {
      VIGENTE = "VIGENTE",
      VENCIDO = "VENCIDO",
      PAGADO = "PAGADO",
      PRORROGA_POR_CONTINGENCIA = "PRORROGA_POR_CONTINGENCIA",
      FUTURO = "FUTURO",
      EN_TRÁMITE_PAGAR_CLIENTE = "EN_TRÁMITE_PAGAR_CLIENTE",
      EN_TRÁMITE_DESCONTADO_MONTO_ADJ = "EN_TRÁMITE_DESCONTADO_MONTO_ADJ",
      EN_TRÁMITE_DESCUENTO_DEL_MONTO_ADJ_POR_AFIRMATIVA_FICTA = "EN_TRÁMITE_DESCUENTO_DEL_MONTO_ADJ_POR_AFIRMATIVA_FICTA",
      ELIMINADO_PARCIALIDAD_PRORROGA = "ELIMINADO_PARCIALIDAD_PRORROGA",
      ELIMINADO = "ELIMINADO"
    }

    export enum EstatusSesion {
      Inicio_Sesion = "Inicio_Sesion",
      Recuperacion_Manual = "Recuperacion_Manual",
      Recuperacion_Automatica = "Recuperacion_Automatica",
      Alta_Usuario = "Alta_Usuario",
      Actualizacion_Domicilio = "Actualizacion_Domicilio"
    }

    export enum EstatusTramiteSeguro {
      EN_TRAMITE = "EN_TRAMITE",
      PENDIENTE = "PENDIENTE",
      ACEPTADO_EN_SU_TOTALIDAD = "ACEPTADO_EN_SU_TOTALIDAD",
      EXTRAPRIMADO = "EXTRAPRIMADO",
      EXTRAPRIMADO_Y_RECHAZADO_EN_INVALIDEZ = "EXTRAPRIMADO_Y_RECHAZADO_EN_INVALIDEZ",
      EXTRAPRIMADO_Y_RECHAZADO_EN_INVALIDEZ_Y_SEG_DESEMPLEO = "EXTRAPRIMADO_Y_RECHAZADO_EN_INVALIDEZ_Y_SEG_DESEMPLEO",
      EXTRAPRIMADO_Y_RECHAZADO_EN_SEG_DESEMPLEO = "EXTRAPRIMADO_Y_RECHAZADO_EN_SEG_DESEMPLEO",
      PENDIENTE_DE_DICTAMEN = "PENDIENTE_DE_DICTAMEN",
      RECHAZADO_EN_INVALIDEZ = "RECHAZADO_EN_INVALIDEZ",
      RECHAZADO_EN_INVALIDEZ_Y_SEG_DESEMPLEO = "RECHAZADO_EN_INVALIDEZ_Y_SEG_DESEMPLEO",
      RECHAZADO_EN_SEG_DESEMPLEO = "RECHAZADO_EN_SEG_DESEMPLEO",
      RECHAZADO_EN_SU_TOTALIDAD = "RECHAZADO_EN_SU_TOTALIDAD",
      REQUIERE_EXAMEN_MEDICO = "REQUIERE_EXAMEN_MEDICO",
      REQUIERE_INFORMACION_ADICIONAL = "REQUIERE_INFORMACION_ADICIONAL",
      CESIÓN_DERECHOS = "CESIÓN_DERECHOS",
      CANCELACIÓN = "CANCELACIÓN",
      RECONSIDERACIÓN_ASEGURADORA = "RECONSIDERACIÓN_ASEGURADORA",
      ELIMINADO = "ELIMINADO",
      CANCELADO_POR_CESIÓN_DERECHOS = "CANCELADO_POR_CESIÓN_DERECHOS"
    }

    export enum EstudioSocioeconomicoEntidad {
      CLIENTE = "CLIENTE",
      OBLIGADO_SOLIDARIO = "OBLIGADO_SOLIDARIO",
      COTITULAR = "COTITULAR",
      GARANTE_HIPOTECARIO = "GARANTE_HIPOTECARIO",
      VENDEDOR = "VENDEDOR",
      CLIENTE_PERSONA_MORAL = "CLIENTE_PERSONA_MORAL",
      CONYUGE = "CONYUGE",
      CONYUGE_VENDEDOR = "CONYUGE_VENDEDOR",
      CONYUGE_GARANTE_HIPOTECARIO = "CONYUGE_GARANTE_HIPOTECARIO",
      CLIENTE_INMUEBLE_GARANTIA = "CLIENTE_INMUEBLE_GARANTIA",
      CLIENTE_PERSONA_MORAL_APODERADO = "CLIENTE_PERSONA_MORAL_APODERADO",
      CLIENTE_MORAL_REPRESENTANTE_LEGAL = "CLIENTE_MORAL_REPRESENTANTE_LEGAL",
      VENDEDOR_PERSONA_MORAL = "VENDEDOR_PERSONA_MORAL",
      VENDEDOR_INMUEBLE_GARANTIA = "VENDEDOR_INMUEBLE_GARANTIA",
      VENDEDOR_PERSONA_MORAL_REPRESENTANTE_LEGAL = "VENDEDOR_PERSONA_MORAL_REPRESENTANTE_LEGAL",
      COTITULAR_CONYUGE = "COTITULAR_CONYUGE"
    }

    export enum FormaPago {
      EFECTIVO = "EFECTIVO",
      CHEQUE = "CHEQUE",
      TRANSFERENCIA_ELECTRÓNICA = "TRANSFERENCIA_ELECTRÓNICA",
      TARJETA_DE_CRÉDITO = "TARJETA_DE_CRÉDITO",
      MONEDERO_ELECTRÓNICO = "MONEDERO_ELECTRÓNICO",
      DINERO_ELECTRÓNICO = "DINERO_ELECTRÓNICO",
      VALES_DE_DESPENSA = "VALES_DE_DESPENSA",
      TARJETA_DE_DÉBITO = "TARJETA_DE_DÉBITO",
      TARJETA_DE_SERVICIO = "TARJETA_DE_SERVICIO",
      OTROS = "OTROS",
      MERCADO_PAGO = "MERCADO_PAGO"
    }

    export enum OrigenTransaccion {
      CONVERSION = "CONVERSION",
      RAP = "RAP",
      SALDO_A_FAVOR = "SALDO_A_FAVOR",
      NO_APLICADOS = "NO_APLICADOS",
      CUENTA_PUENTE = "CUENTA_PUENTE",
      MANUAL_NO_APLICADOS = "MANUAL_NO_APLICADOS",
      DISMINUCION = "DISMINUCION",
      TRASPASO = "TRASPASO",
      MANUAL_NO_APLICADOS_ = "MANUAL_NO_APLICADOS_",
      COMISION = "COMISION",
      FUSION = "FUSION"
    }

    export enum Propietario {
      GENERAL = "GENERAL",
      ATC = "ATC",
      JURIDICO = "JURIDICO"
    }

    export enum Segmentacion {
      NORMAL = "NORMAL",
      LIQUIDACION = "LIQUIDACION",
      PAGO_POR_SINIESTRO = "PAGO_POR_SINIESTRO",
      SEGURO_DE_DESEMPLEO = "SEGURO_DE_DESEMPLEO",
      ADJUDICACION_OBLIGATORIA = "ADJUDICACION_OBLIGATORIA",
      INGRESO_A_LISTA_DE_ESPERA = "INGRESO_A_LISTA_DE_ESPERA",
      CONVENIO_DE_PAGOS = "CONVENIO_DE_PAGOS",
      NEGOCIACION_JURIDICO = "NEGOCIACION_JURIDICO",
      TRASPASO = "TRASPASO",
      LIQUIDACIÓN_BPR = "LIQUIDACIÓN_BPR",
      RECUPERACIÓN_BPR = "RECUPERACIÓN_BPR",
      MUTUO_PARA_PREVENTA = "MUTUO_PARA_PREVENTA",
      PRORROGA = "PRORROGA",
      APOYO_COVID_19 = "APOYO_COVID_19",
      PAGO_CON_DESCUENTO_EN_C_ADMON = "PAGO_CON_DESCUENTO_EN_C_ADMON"
    }

    export enum TipoConstruccion {
      CASA_HABITACION = "CASA_HABITACION",
      LOCAL_COMERCIAL = "LOCAL_COMERCIAL"
    }

    export enum TipoDocumentoDomiciliacion {
      Identificacion_Oficial_Cliente = "Identificacion_Oficial_Cliente",
      Estado_Cuenta_Cliente = "Estado_Cuenta_Cliente",
      Identificacion_Oficial_Tercero = "Identificacion_Oficial_Tercero",
      Estado_Cuenta_Tercero = "Estado_Cuenta_Tercero",
      Formato_Domiciliacion_Tercero = "Formato_Domiciliacion_Tercero"
    }

    export enum TipoFuenteIngreso {
      EMPLEADO_POR_NOMINA = "EMPLEADO_POR_NOMINA",
      NEGOCIO_PROPIO = "NEGOCIO_PROPIO",
      ACTIVIDAD_EMPRESARIAL = "ACTIVIDAD_EMPRESARIAL",
      ARRENDAMIENTO = "ARRENDAMIENTO",
      CONCESIONES = "CONCESIONES",
      PENSION = "PENSION",
      OTROS = "OTROS",
      CONYUGE = "CONYUGE"
    }

    export enum TipoMaterialApoyo {
      Titulo = "Titulo",
      Texto = "Texto",
      SubTitulo = "SubTitulo"
    }

    export enum TipoMenuMaterialApoyo {
      Bienvenida = "Bienvenida",
      Pagos = "Pagos",
      Tramites = "Tramites",
      Adjudicación = "Adjudicación",
      Escrituración = "Escrituración",
      Seguros = "Seguros",
      Liquidación = "Liquidación"
    }

    export enum TipoProducto {
      HIR_CASA = "HIR_CASA",
      HIR_AUTO = "HIR_AUTO",
      HIR_EFECTIVO = "HIR_EFECTIVO",
      HIR_CASA_FIJO = "HIR_CASA_FIJO"
    }

    export enum TipoRecibo {
      APORTACIÓN = "APORTACIÓN",
      CUOTA_DE_INSCRIPCIÓN = "CUOTA_DE_INSCRIPCIÓN",
      SEGUNDA_CUOTA_DE_INSCRIPCIÓN = "SEGUNDA_CUOTA_DE_INSCRIPCIÓN",
      CESIÓN_DE_DERECHOS = "CESIÓN_DE_DERECHOS",
      INSCRIPCIÓN_POR_INCREMENTO = "INSCRIPCIÓN_POR_INCREMENTO",
      APORTACIÓN_POR_DISMINUCIÓN = "APORTACIÓN_POR_DISMINUCIÓN",
      APORTACIÓN_POR_INCREMENTO = "APORTACIÓN_POR_INCREMENTO",
      APORTACIÓN_FIJA_HIRAUTO = "APORTACIÓN_FIJA_HIRAUTO",
      APORTACIÓN_FIJA_HIRCASA = "APORTACIÓN_FIJA_HIRCASA",
      APORTACIÓN_PRORROGADA = "APORTACIÓN_PRORROGADA"
    }

    export enum TipoSinestro {
      FALLECIMIENTO = "FALLECIMIENTO",
      INVALIDEZ_TOTAL_PERMANENTE = "INVALIDEZ_TOTAL_PERMANENTE",
      DESEMPLEO_1A_MENSUALIDAD = "DESEMPLEO_1A_MENSUALIDAD",
      DESEMPLEO_2A_MENSUALIDAD = "DESEMPLEO_2A_MENSUALIDAD",
      DESEMPLEO_3A_MENSUALIDAD = "DESEMPLEO_3A_MENSUALIDAD",
      DAÑOS = "DAÑOS"
    }

    export enum UsoInmueble {
      HABITAR = "HABITAR",
      RENTAR = "RENTAR",
      CASA_DE_DESCANSO = "CASA_DE_DESCANSO",
      INVERSION = "INVERSION",
      HABITABLE_POR_TERCEROS = "HABITABLE_POR_TERCEROS"
    }
  }

  export namespace Models {
    export interface Aclaracion {
      aclaracionId: number;
      fecha: string;
      clienteId: number;
      tipoAclaracionId: number;
      detalle?: string;
      fechaTerminacion?: string;
      archivo: boolean;
      tipoAclaracion: TipoAclaracion;
    }

    export interface AclaracionUsuario {
      aclaracionUsuarioId: number;
      aclaracionId: number;
      usuarioId: number;
      aclaracion: Aclaracion;
      usuario: Usuario;
    }

    export interface AltaUsuario {
      altaUsuarioID: number;
      numContrato?: string;
      correo?: string;
      password?: string;
      estatus: Enums.EstatusAlta;
      fechaRegistro: string;
      clienteId: number;
      cliente: Cliente;
    }

    export interface CalendarioCorte {
      calendarioCorteId: number;
      planId: number;
      fechaEmision: string;
      fechaPagosAl: string;
      fechaVigenciaRecibo: string;
      fechaVigenciaTasaActualizacion: string;
      plan: Plan;
    }

    export interface Ciudad {
      ciudadId: number;
      descripcion?: string;
      estadoId: number;
      estado: Estado;
    }

    export interface Cliente {
      clienteId: number;
      prospectoId: number;
      personaFisicaId?: number;
      personaMoralId?: number;
      tipo: TipoCliente;
      activo: boolean;
      arco: boolean;
      programaRecicla: boolean;
      salesForceCuentaId?: string;
      programaEspecial?: ProgramaEspecial;
      referenciaUnica?: string;
      clienteReferenciaId?: number;
      aceptaTerminosCondicionesAvisoPrivacidad: boolean;
      imagenPerfil?: string;
      personaFisica: PersonaFisica;
      personaMoral: PersonaMoral;
      listasNegras?: ListaNegra[];
      esPersonaFisica: boolean;
      telefono1?: string;
      telefono2?: string;
      telefono3?: string;
      email1?: string;
      email2?: string;
      pais?: string;
      codigoPostal?: string;
      calleNumero?: string;
      colonia?: string;
      delegacion?: string;
      estado?: string;
    }

    export interface CodigoPostal {
      codigoPostalId: number;
      descripcion?: string;
      ciudadId: number;
      ciudad: Ciudad;
      colonias?: Colonia[];
    }

    export interface Colonia {
      coloniaId: number;
      descripcion?: string;
      codigoPostalId: number;
      codigoPostal: CodigoPostal;
    }

    export interface Contrato {
      contratoId: number;
      solicitudId: number;
      folioId: number;
      estatus: EstatusContrato;
      estatusLegal: EstatusContratoLegal;
      estadoProcesal: number;
      fondeo?: TipoFondeo;
      montoContratado: number;
      pagoAutomatico: boolean;
      poderNotarialId?: number;
      candadoTransacciones: boolean;
      montoDisponible: number;
      folio: Folio;
      solicitud: Solicitud;
      transaccionesAnticipo?: TransaccionAnticipo[];
      recibos?: Recibo[];
    }

    export interface ContratoGarantia {
      contratoGarantiaId: number;
      contratoId: number;
      garantiaAdministradaId: number;
      operacionId?: number;
      estatusComplemento: EstatusComplementoCI;
      fechaEscriturado?: string;
      valorGarantiaInicial?: number;
      valorGarantiaActualizada?: number;
      valorGarantiaActualizadaINPC?: number;
      actualizacionCalendarioCorteId?: number;
      montoAportadoCapitalAlEscriturar?: number;
      cuotaPuntual?: number;
      cuotaNoPuntual?: number;
      cuotaAnticipada?: number;
      cuotaTotalPagada?: number;
      montoActualizadoAlEscriturar?: number;
      activo: boolean;
      ejecutivo?: string;
      fechaEntregaDocumentacion?: string;
      loanToValueOriginal: number;
      loanToValueActual: number;
      contrato: Contrato;
      garantia: GarantiaAdministrada;
      operacion: Operacion;
    }

    export interface Deposito {
      depositoId: number;
      estatus: Enums.EstatusDeposito;
      fechaTransaccion: string;
      monto: number;
      formaPago: Enums.FormaPago;
      descripcion?: string;
      referenciaCompleta?: string;
      producto: Enums.TipoProducto;
      categoria: Enums.CategoriasDeposito;
      origenId: number;
    }

    export interface DocumentoTramiteTitulacion {
      documentoTramiteTitulacionId: number;
      nombre?: string;
      entidadDocumentoId: number;
      activo: boolean;
      categoriaDocumentoTramiteTitulacion: Enums.CategoriaDocumentoTramiteTitulacion;
    }

    export interface Estado {
      estadoId: number;
      descripcion?: string;
      paisId: number;
      pais: Pais;
    }

    export enum EstadoCivil {
      SOLTERO = "SOLTERO",
      CASADO = "CASADO",
      VIUDO = "VIUDO",
      DIVORCIADO = "DIVORCIADO",
      UNIÓN_LIBRE = "UNIÓN_LIBRE"
    }

    export interface EstadoDocumentoEstudioSocioeconomico {
      estadoDocumentoEstudioSocioeconomicoId: number;
      nombre?: string;
      color?: string;
      operador?: string;
      diasHabilesPeriodicidad: number;
      activo: boolean;
    }

    export enum EstatusComplementoCI {
      EN_TRÁMITE = "EN_TRÁMITE",
      PAGADA_POR_EL_CLIENTE = "PAGADA_POR_EL_CLIENTE",
      DESCONTADA_DEL_MONTO_ADJUDICADO = "DESCONTADA_DEL_MONTO_ADJUDICADO",
      CONDONACIÓN_DE_PAGO = "CONDONACIÓN_DE_PAGO"
    }

    export enum EstatusContrato {
      ACTIVO = "ACTIVO",
      CANCELADO = "CANCELADO",
      RESCINDIDO = "RESCINDIDO",
      LIQUIDADO = "LIQUIDADO",
      CANCELACIÓN_EN_TRÁMITE = "CANCELACIÓN_EN_TRÁMITE",
      SUSPENDIDO = "SUSPENDIDO",
      CANCELADO_VENTA_FORÁNEA = "CANCELADO_VENTA_FORÁNEA",
      CANCELADO_DESISTIMIENTO = "CANCELADO_DESISTIMIENTO",
      CANCELACION_POR_DUPLICIDAD = "CANCELACION_POR_DUPLICIDAD",
      CANCELACION_PRUEBAS = "CANCELACION_PRUEBAS",
      FOLIO_REASIGNADO = "FOLIO_REASIGNADO",
      LIQUIDACIÓN_EN_TRÁMITE = "LIQUIDACIÓN_EN_TRÁMITE"
    }

    export enum EstatusContratoLegal {
      NORMAL = "NORMAL",
      OBSERVADO = "OBSERVADO",
      JUICIO_DEMANDADO = "JUICIO_DEMANDADO",
      PROFECO = "PROFECO",
      PROMESA_DACIÓN = "PROMESA_DACIÓN",
      JUICIO_ACTOR = "JUICIO_ACTOR",
      PRÉSTAMO_BPR = "PRÉSTAMO_BPR"
    }

    export enum EstatusFolio {
      RESERVADO_CORP = "RESERVADO_CORP",
      DISPONIBLE = "DISPONIBLE",
      ASIGNADO = "ASIGNADO",
      RE_ASIGNABLE = "RE_ASIGNABLE",
      NO_ASIGNABLE = "NO_ASIGNABLE",
      RESERVADO_ADMON_SUCURSALES = "RESERVADO_ADMON_SUCURSALES"
    }

    export enum EstatusSolicitud {
      EN_PROCESO = "EN_PROCESO",
      CANCELADA = "CANCELADA",
      COMITÉ = "COMITÉ",
      CONVERSIÓN = "CONVERSIÓN",
      CONTRATACIÓN = "CONTRATACIÓN",
      FACTURACIÓN = "FACTURACIÓN",
      CONCRETADA = "CONCRETADA",
      CANCELADA_VENTA_FORÁNEA = "CANCELADA_VENTA_FORÁNEA",
      ELIMINADA_POR_DUPLICIDAD = "ELIMINADA_POR_DUPLICIDAD",
      CANCELADA_POR_PRUEBAS = "CANCELADA_POR_PRUEBAS"
    }

    export interface EstudioSocioeconomicoDocumento {
      documentoTramiteTitulacionId: number;
      posicion?: string;
      especificacion?: string;
      descripcion?: string;
      dondeConseguir?: string;
      mesesVigencia?: number;
      porqueNecesario?: string;
      configuracion?: string;
      guardado: boolean;
      estatus?: number;
      comentario?: string;
      fechaVigencia?: string;
      estado: EstadoDocumentoEstudioSocioeconomico;
      created_at?: string;
      archivo?: string;
      linkArchivo?: string;
      tipoArchivo?: string;
      documentoTramiteTitulacion: DocumentoTramiteTitulacion;
    }

    export enum EtapaOperacionTitulacion {
      IDENTIFICACION_DEL_PROYECTO = "IDENTIFICACION_DEL_PROYECTO",
      INTEGRACION_DE_EXPEDIENTE = "INTEGRACION_DE_EXPEDIENTE",
      ANÁLISIS = "ANÁLISIS",
      FIRMA = "FIRMA",
      FIN = "FIN"
    }

    export interface FactorActualizacion {
      factorActualizacionId: number;
      nombreFactor?: string;
      tipo: number;
      coeficiente?: string;
      plans?: Plan[];
    }

    export interface Folio {
      folioId: number;
      grupoId: number;
      numeroFolio: number;
      estatusFolio: EstatusFolio;
      numeroCompleto?: string;
      referenciaUnica?: string;
      sucursalId?: number;
    }

    export interface GarantiaAdministrada {
      garantiaAdministradaId: number;
      clienteId: number;
      cotitularId?: number;
      notarioId?: number;
      testimonioId?: number;
      descripcion?: string;
      calle?: string;
      numeroExterior?: string;
      numeroInterior?: string;
      codigoPostalCampoAbierto?: string;
      coloniaCampoAbierto?: string;
      coloniaId?: number;
      ciudadId: number;
      estadoId: number;
      productoContratado: ProductoContratado;
      tipoProducto: TipoProductoGarantia;
      valorGarantia: number;
      valorGarantiaActualizado: number;
      valorTotalContratos: number;
      fechaAvaluo?: string;
      certificadoDanios: boolean;
      calculaSeguroDanios: boolean;
      polizaAplicar: TipoPoliza;
      valorAsegurado: number;
      entregaArchivo: boolean;
      numeroEscritura?: string;
      activo: boolean;
      fechaUltimaActualizacion?: string;
      referenciaUnica?: string;
      modeloEscritura: number;
      alias?: string;
      notario: Notario;
      cliente: Cliente;
      operaciones?: Operacion[];
      contratos?: ContratoGarantia[];
      garantiaAdministradaDocumentos?: GarantiaAdministradaDocumento[];
      colonia: Colonia;
    }

    export interface GarantiaAdministradaDocumento {
      garantiaAdministradaDocumentoId: number;
      garantiaAdministradaId: number;
      tipoDocumento: TipoDocumentoTramiteSeguro;
      ruta?: string;
      fechaCarga: string;
    }

    export enum GradoEstudios {
      PRIMARIA = "PRIMARIA",
      SECUNDARIA = "SECUNDARIA",
      MEDIO_SUPERIOR = "MEDIO_SUPERIOR",
      TÉCNICO = "TÉCNICO",
      LICENCIATURA_POSGRADO = "LICENCIATURA_POSGRADO",
      NINGUNA_DE_LAS_ANTERIORES = "NINGUNA_DE_LAS_ANTERIORES"
    }

    export enum Identificacion {
      INE = "INE",
      PASAPORTE = "PASAPORTE",
      CÉDULA_PROFESIONAL = "CÉDULA_PROFESIONAL",
      LICENCIA_CONDUCIR = "LICENCIA_CONDUCIR",
      SEPOMEX = "SEPOMEX",
      DOCUMENTO_EXTRANJERO = "DOCUMENTO_EXTRANJERO",
      OTROS_NO_AUTORIZADOS_POR_LA_EMPRESA = "OTROS_NO_AUTORIZADOS_POR_LA_EMPRESA"
    }

    export interface Ingreso {
      ingresoId: number;
      personaFisicaId?: number;
      personaMoralId?: number;
      tipoIngreso: TipoIngreso;
      empresa?: string;
      giroEmpresaId: number;
      puestoId: number;
      fechaIngreso?: string;
      antiguedadMes: number;
      antiguedadAnio: number;
      montoIngreso: number;
      utilidades: number;
      confirmadoVia?: string;
      confirma?: string;
      activo: boolean;
    }

    export interface ListaNegra {
      listaNegraId: number;
      nombre?: string;
      propietario: Enums.Propietario;
      activo: boolean;
      clientes?: Cliente[];
    }

    export interface MaterialApoyo {
      materialApoyoId: number;
      descripcion?: string;
      tipo: Enums.TipoMaterialApoyo;
      ruta?: string;
      tipoMenu: Enums.TipoMenuMaterialApoyo;
    }

    export interface Notario {
      notarioId: number;
      nombreCompleto?: string;
      numeroNotaria: number;
      estadoId: number;
      ciudadId: number;
      activo: boolean;
    }

    export interface Operacion {
      operacionId: number;
      folio?: string;
      ejecutivo?: string;
      fechaPresentacionComite?: string;
      tipo: number;
      fechaInicioCumplimiento?: string;
      fechaFinCumplimiento?: string;
      datosOperacion?: string;
      montoOperacion?: number;
      costoNotarial?: number;
      dictamenJuridico?: string;
      pronunciamientoEscrituraciones?: string;
      activo: boolean;
      clienteId?: number;
      iIdOperacionEscrituracion?: number;
      garantiaAdministradaId?: number;
      idSistema?: number;
      destinoInmueble?: string;
      analista?: string;
      tipoCartaInstruccion?: number;
      lugarFirma?: string;
      fechaFirma?: string;
      horaFirma?: System.TimeSpan;
      condicionantes?: string;
      estatusOperacion: number;
      resolucionComite?: number;
      fechaResolucion?: string;
      votoCalidad: boolean;
      usuarioIdVotoCalidad?: number;
      candadoQuorum: boolean;
    }

    export interface Pais {
      paisId: number;
      codigo?: string;
      descripcion?: string;
      gentilicio?: string;
      activo: boolean;
      descripcionIngles?: string;
      gentilicioIngles?: string;
      lada?: string;
    }

    export enum Parentesco {
      CÓNYUGE = "CÓNYUGE",
      CONCUBINO_A_ = "CONCUBINO_A_",
      MADRE = "MADRE",
      PADRE = "PADRE",
      HERMANO_A_ = "HERMANO_A_",
      HIJO_A_ = "HIJO_A_",
      ABUELO_A_ = "ABUELO_A_",
      NIETO_A_ = "NIETO_A_",
      TÍO_A_ = "TÍO_A_",
      SOBRINO_A_ = "SOBRINO_A_",
      PRIMO_A_ = "PRIMO_A_",
      OTRO = "OTRO",
      AMIGO_A = "AMIGO_A"
    }

    export interface PersonaFisica {
      personaFisicaId: number;
      nombre?: string;
      apellidoPaterno?: string;
      apellidoMaterno?: string;
      sexo?: Sexo;
      fechaNacimiento?: string;
      documentoIdentificacion?: Identificacion;
      numeroIdentificacion?: string;
      paisNacimientoId?: number;
      paisNacionalidadId?: number;
      rfc?: string;
      estadoCivil?: EstadoCivil;
      regimenMatrimonial?: RegimenMatrimonial;
      conyuge?: string;
      profesionId?: number;
      otraProfesion?: string;
      numeroDependientes?: number;
      egreso?: number;
      tipoVivienda?: TipoVivienda;
      gradoEstudio?: GradoEstudios;
      nombreCompleto?: string;
      recibirPublicidad: boolean;
      compartirDatos: boolean;
      facturar: boolean;
      curp?: string;
      regimenFiscalId?: number;
      ubicaciones?: Ubicacion[];
      ingresos?: Ingreso[];
      puntosContacto?: PuntoContacto[];
      empresasRepresentadas?: PersonaMoral[];
      profesion: Profesion;
      regimenFiscal: RegimenFiscal;
      telefonoCasa?: string;
      telefonoOficina?: string;
      telefonoCelular?: string;
      email?: string;
      emailOficina?: string;
    }

    export interface PersonaMoral {
      personaMoralId: number;
      razonSocial?: string;
      nombreComercial?: string;
      fechaConstitucion?: string;
      giroEmpresaId: number;
      rfc?: string;
      calle?: string;
      numero?: string;
      coloniaId?: number;
      email?: string;
      facturar: boolean;
      regimenFiscalId?: number;
      colonia: Colonia;
      regimenFiscal: RegimenFiscal;
      representantesLegales?: PersonaFisica[];
      telefonoOficina?: string;
    }

    export interface Plan {
      planId: number;
      nombre?: string;
      producto: number;
      capacidad: number;
      plazo: number;
      factorActualizacionId: number;
      montoMinimo: number;
      montoMaximo: number;
      montoMaximoGrupoCliente: number;
      administracion?: string;
      factorMoratorio: number;
      cuentaPuenteId?: number;
      cuentaOperativaId?: number;
      cuentaPenalizacionId?: number;
      empresa: number;
      modeloContratoLegal?: string;
      factorActualizacion: FactorActualizacion;
      calendarioCortes?: CalendarioCorte[];
    }

    export enum ProcedimientoAdjudicacion {
      OBLIGATORIA = "OBLIGATORIA",
      NATURAL = "NATURAL",
      LISTA_DE_ESPERA = "LISTA_DE_ESPERA"
    }

    export enum ProductoContratado {
      CASA_DEPARTAMENTO = "CASA_DEPARTAMENTO",
      OFICINA_COMERCIO = "OFICINA_COMERCIO",
      LOCAL_COMERCIAL = "LOCAL_COMERCIAL",
      TERRENO = "TERRENO",
      NO_DEFINIDO = "NO_DEFINIDO"
    }

    export interface Profesion {
      profesionId: number;
      descripcion?: string;
    }

    export enum ProgramaEspecial {
      NORMAL = "NORMAL",
      PROGRAMA_EXTRANJEROS = "PROGRAMA_EXTRANJEROS",
      FIRMA_DIGITAL = "FIRMA_DIGITAL"
    }

    export interface PuntoContacto {
      puntoContactoId: number;
      personaFisicaId: number;
      tipoContacto: TipoContacto;
      valor?: string;
      extension?: string;
      activo: boolean;
      paisId?: number;
    }

    export interface Recibo {
      reciboId: number;
      contratoId: number;
      tipo: Enums.TipoRecibo;
      estatus: Enums.EstatusRecibo;
      fechaGeneracion: string;
      montoActualizadoContrato: number;
      montoTotal: number;
      contrato: Contrato;
    }

    export interface RegimenFiscal {
      regimenFiscalId: number;
      nombreRegimen?: string;
      tipoCliente: TipoCliente;
    }

    export enum RegimenMatrimonial {
      SOCIEDAD_CONYUGAL = "SOCIEDAD_CONYUGAL",
      SEPARACIÓN_DE_BIENES = "SEPARACIÓN_DE_BIENES"
    }

    export interface Rol {
      rolId: number;
      nombre?: string;
      descripcion?: string;
      configuracion?: string;
      sucursales?: string;
      rolSupervisorId?: number;
      horaInicioAtencion?: System.TimeSpan;
      horaFinAtencion?: System.TimeSpan;
      activo: boolean;
      usuarios?: Usuario[];
      usuarioRols?: UsuarioRol[];
      rolSupervisor: Rol;
    }

    export enum Sexo {
      HOMBRE = "HOMBRE",
      MUJER = "MUJER"
    }

    export interface Solicitud {
      solicitudId: number;
      clienteId: number;
      fechaOperacion?: string;
      producto: Enums.TipoProducto;
      monto?: number;
      planId?: number;
      sucursalId: number;
      estatusSolicitud: EstatusSolicitud;
      tipoVenta: TipoVenta;
      configuracionDispersion?: string;
      asesor?: string;
      asesorRVT?: string;
      gerenciaId?: number;
      proyectoId?: number;
      salesForceOportunidadId?: string;
      cliente: Cliente;
      plan: Plan;
    }

    export interface TipoAclaracion {
      tipoAclaracionId: number;
      nombre?: string;
    }

    export enum TipoCliente {
      PERSONA_FÍSICA = "PERSONA_FÍSICA",
      PERSONA_MORAL = "PERSONA_MORAL"
    }

    export enum TipoContacto {
      TELÉFONO_PARTICULAR = "TELÉFONO_PARTICULAR",
      TELÉFONO_OFICINA = "TELÉFONO_OFICINA",
      TELÉFONO_CELULAR = "TELÉFONO_CELULAR",
      EMAIL_PARTICULAR = "EMAIL_PARTICULAR",
      EMAIL_OFICINA = "EMAIL_OFICINA",
      TELÉFONO_FAX_RADIOLOCALIZADOR = "TELÉFONO_FAX_RADIOLOCALIZADOR"
    }

    export enum TipoDocumentoTramiteSeguro {
      CONSENTIMIENTO_MÉDICO = "CONSENTIMIENTO_MÉDICO",
      DOCUMENTOS_ANEXOS = "DOCUMENTOS_ANEXOS",
      PASE_MÉDICO = "PASE_MÉDICO",
      CERTIFICADO_SEGURO_VIDA = "CERTIFICADO_SEGURO_VIDA",
      CERTIFICADO_SEGURO_DAÑOS = "CERTIFICADO_SEGURO_DAÑOS"
    }

    export enum TipoFondeo {
      NAFIN = "NAFIN",
      BANCOMER = "BANCOMER",
      HIR_CASA = "HIR_CASA",
      BANCOMER_II_D1 = "BANCOMER_II_D1",
      BANCOMER_II_D2 = "BANCOMER_II_D2",
      BANCOMER_II_D3 = "BANCOMER_II_D3",
      NAFIN_II_D1 = "NAFIN_II_D1",
      NAFIN_II_D2 = "NAFIN_II_D2",
      NAFIN_II_D3 = "NAFIN_II_D3",
      NAFIN_II_D4 = "NAFIN_II_D4",
      BANCOMER_III_D1 = "BANCOMER_III_D1",
      BANCOMER_III_D2 = "BANCOMER_III_D2",
      BANCOMER_III_D3 = "BANCOMER_III_D3",
      BANCOMER_III_D4 = "BANCOMER_III_D4",
      BANCOMER_IV_D1 = "BANCOMER_IV_D1",
      BANCOMER_IV_D2 = "BANCOMER_IV_D2",
      BX_MAS_D1 = "BX_MAS_D1",
      BANCOMER_IV_D3 = "BANCOMER_IV_D3",
      BANCOMER_IV_D4 = "BANCOMER_IV_D4",
      BX_MAS_D2 = "BX_MAS_D2",
      BX_MAS_D3 = "BX_MAS_D3",
      BX_MAS_D4 = "BX_MAS_D4",
      BX_MAS_II_D1 = "BX_MAS_II_D1",
      BAJIO_D1 = "BAJIO_D1",
      BANCOMER_V_D1 = "BANCOMER_V_D1",
      BAJIO_D2 = "BAJIO_D2",
      BAJIO_D3 = "BAJIO_D3",
      BANCOMER_V_D2 = "BANCOMER_V_D2",
      BAJIO_D4 = "BAJIO_D4",
      BANCOMER_V_D3 = "BANCOMER_V_D3",
      BANCOMER_V_D4 = "BANCOMER_V_D4",
      BANCOMER_V_D5 = "BANCOMER_V_D5",
      BANCOMER_V_D6 = "BANCOMER_V_D6",
      SINDICADO_D1 = "SINDICADO_D1",
      SINDICADO_D2 = "SINDICADO_D2",
      SINDICADO_D3 = "SINDICADO_D3",
      SINDICADO_D4 = "SINDICADO_D4",
      SANTANDER_D1 = "SANTANDER_D1",
      SANTANDER_D2 = "SANTANDER_D2",
      SANTANDER_D3 = "SANTANDER_D3",
      SANTANDER_D4 = "SANTANDER_D4",
      HIR_CASA_F_3317 = "HIR_CASA_F_3317",
      BURSATILIZADA_I = "BURSATILIZADA_I",
      BURSATILIZADA_II = "BURSATILIZADA_II",
      PRÉSTAMO_BPR = "PRÉSTAMO_BPR"
    }

    export enum TipoIngreso {
      COMPROBABLE = "COMPROBABLE",
      NO_COMPROBABLE = "NO_COMPROBABLE"
    }

    export enum TipoPension {
      PENSION_POR_INCAPACIDAD_PARCIAL_PERMANENTE = "PENSION_POR_INCAPACIDAD_PARCIAL_PERMANENTE",
      PENSION_DE_VIUDEZ_O_CONCUBINATO_A_CAUSA_DE_UN_ACCIDENTE_DE_TRABAJO = "PENSION_DE_VIUDEZ_O_CONCUBINATO_A_CAUSA_DE_UN_ACCIDENTE_DE_TRABAJO",
      PENSION_DE_ORFANDAD_A_CAUSA_DE_UN_ACCIDENTE_DE_TRABAJO = "PENSION_DE_ORFANDAD_A_CAUSA_DE_UN_ACCIDENTE_DE_TRABAJO",
      PENSION_POR_CESANTIA_EN_EDAD_AVANZADA = "PENSION_POR_CESANTIA_EN_EDAD_AVANZADA",
      PENSION_POR_VEJEZ = "PENSION_POR_VEJEZ",
      PENSION_POR_INVALIDEZ = "PENSION_POR_INVALIDEZ",
      PENSION_POR_RETIRO = "PENSION_POR_RETIRO"
    }

    export enum TipoPoliza {
      SIN_DEFINIR = "SIN_DEFINIR",
      VALOR_DE_LOS_CONTRATOS = "VALOR_DE_LOS_CONTRATOS",
      VALOR_DEL_BIEN = "VALOR_DEL_BIEN"
    }

    export enum TipoProductoGarantia {
      COMPRA_VENTA_DE_CASA_O_DEPARTAMENTO = "COMPRA_VENTA_DE_CASA_O_DEPARTAMENTO",
      COMPRA_VENTA_DE_LOCAL_COMERCIAL = "COMPRA_VENTA_DE_LOCAL_COMERCIAL",
      COMPRA_VENTA_Y_REMODELACIÓN = "COMPRA_VENTA_Y_REMODELACIÓN",
      COMPRA_DE_TERRENO_Y_CONSTRUCCION = "COMPRA_DE_TERRENO_Y_CONSTRUCCION",
      COMPRA_DE_TERRENO_SIN_CONSTRUCCION = "COMPRA_DE_TERRENO_SIN_CONSTRUCCION",
      CONSTRUCCION = "CONSTRUCCION",
      REMODELACION = "REMODELACION",
      NO_DEFINIDO = "NO_DEFINIDO"
    }

    export enum TipoProyecto {
      COMPRA_DE_CASA_NUEVA = "COMPRA_DE_CASA_NUEVA",
      COMPRA_DE_CASA_USADA = "COMPRA_DE_CASA_USADA",
      COMPRA_DE_DEPARTAMENTO_NUEVO = "COMPRA_DE_DEPARTAMENTO_NUEVO",
      COMPRA_DE_DEPARTAMENTO_USADO = "COMPRA_DE_DEPARTAMENTO_USADO",
      COMPRA_DE_LOCAL_COMERCIAL = "COMPRA_DE_LOCAL_COMERCIAL",
      COMPRA_DE_TERRENO = "COMPRA_DE_TERRENO",
      REMODELACION = "REMODELACION",
      CONSTRUCCION = "CONSTRUCCION"
    }

    export enum TipoUbicacion {
      PARTICULAR = "PARTICULAR",
      OFICINA = "OFICINA",
      FISCAL = "FISCAL"
    }

    export enum TipoUbicacionGeografica {
      LOCAL = "LOCAL",
      EXTRANJERA = "EXTRANJERA"
    }

    export enum TipoVenta {
      NORMAL = "NORMAL",
      PENDIENTE = "PENDIENTE",
      FORÁNEA = "FORÁNEA"
    }

    export enum TipoVivienda {
      PROPIA = "PROPIA",
      RENTADA = "RENTADA",
      FAMILIAR = "FAMILIAR",
      PROPIA_HIPOTECADA = "PROPIA_HIPOTECADA"
    }

    export interface Transaccion {
      transaccionId: number;
      depositoId: number;
      concepto: Enums.ConceptoTransaccion;
      montoTotal: number;
      fechaAplicacion: string;
      fechaPago: string;
      etapa: number;
      montoActualizado: number;
      contabilizado: boolean;
      fechaContabilizado?: string;
      aportado: boolean;
      fechaAportado?: string;
      facturado: boolean;
      fechaFacturado?: string;
      reversado: boolean;
      transaccionIdReversada?: number;
      fondeo: TipoFondeo;
      segmentacion: Enums.Segmentacion;
      usuarioAplicacion?: string;
      origen: Enums.OrigenTransaccion;
      deposito: Deposito;
      transaccionReversada: Transaccion;
    }

    export interface TransaccionAnticipo {
      transaccionId: number;
      depositoId: number;
      concepto: Enums.ConceptoTransaccion;
      montoTotal: number;
      fechaAplicacion: string;
      fechaPago: string;
      etapa: number;
      montoActualizado: number;
      contabilizado: boolean;
      fechaContabilizado?: string;
      aportado: boolean;
      fechaAportado?: string;
      facturado: boolean;
      fechaFacturado?: string;
      reversado: boolean;
      transaccionIdReversada?: number;
      fondeo: TipoFondeo;
      segmentacion: Enums.Segmentacion;
      usuarioAplicacion?: string;
      origen: Enums.OrigenTransaccion;
      deposito: Deposito;
      transaccionReversada: Transaccion;
      contratoId: number;
      aportacionCapital: number;
      gastoAdministracion: number;
      ivaGastoAdministracion: number;
      remanente: number;
      cuotasCubiertas: number;
      ladrillosObtenidos: number;
      condicionLadrillosObtenidos: Enums.CondicionLadrillo;
      contrato: Contrato;
    }

    export interface Ubicacion {
      ubicacionId: number;
      personaFisicaId: number;
      tipoUbicacion: TipoUbicacion;
      ubicacionGeografica: TipoUbicacionGeografica;
      calle?: string;
      numero?: string;
      tiempoResidencia?: string;
      domicilioFactura: boolean;
      activo: boolean;
      codigoPostalNombre?: string;
      calleNumero?: string;
      coloniaNombre?: string;
      ciudadNombre?: string;
      estadoNombre?: string;
    }

    export interface UbicacionLocal {
      ubicacionId: number;
      personaFisicaId: number;
      tipoUbicacion: TipoUbicacion;
      ubicacionGeografica: TipoUbicacionGeografica;
      calle?: string;
      numero?: string;
      tiempoResidencia?: string;
      domicilioFactura: boolean;
      activo: boolean;
      calleNumero?: string;
      coloniaId: number;
      colonia: Colonia;
      pais: Pais;
      codigoPostalNombre?: string;
      coloniaNombre?: string;
      ciudadNombre?: string;
      estadoNombre?: string;
    }

    export interface Usuario {
      usuarioId: number;
      colaboradorId: number;
      activo: boolean;
      roles?: Rol[];
      usuarioRols?: UsuarioRol[];
    }

    export interface UsuarioRol {
      usuarioId: number;
      usuario: Usuario;
      rolId: number;
      rol: Rol;
    }

    export interface vwContratos {
      entidad?: string;
      contratoId?: number;
      grupoId?: number;
      numeroGrupo?: number;
      numeroCompleto?: string;
      referenciaUnica?: string;
      numeroFolio?: number;
      solicitudId?: number;
      folioId?: number;
      estatus?: number;
      estatusLegal?: number;
      fondeo?: number;
      montoContratado?: number;
      pagoAutomatico?: boolean;
      poderNotarialId?: number;
      fechaLiquidacion?: string;
      etapa?: number;
      montoActualizado?: number;
      montoBasePromedio?: number;
      envioEstadoCuenta?: number;
      estadoCuentaDigital?: boolean;
      participaRemanente?: boolean;
      actualizacionCalendarioCorteId?: number;
      fechaIngresoListaEspera?: string;
      fechaPrimerVencimiento?: string;
      clienteId?: number;
      fechaOperacion?: string;
      montoOperacion?: number;
      sucursalId?: number;
      estatusSolicitud?: number;
      tipoVenta?: number;
      firmaDigital?: number;
      configuracionDispersion?: string;
      asesor?: string;
      asesorRVT?: string;
      gerenciaId?: number;
      proyectoId?: number;
      salesForceOportunidadId?: number;
      servicioContratado?: number;
      productoContratado?: number;
      porcentajeDescuentoInscripcion?: number;
      porcentajeDescuentoComision?: number;
      seguroVida?: number;
      preventa?: number;
      numeroUnidadesComprar?: number;
      proyectoInmobiliarioId?: number;
      tipoViviendaPreventa?: number;
      plazoEfectivo?: number;
      destinoPrestamo?: string;
      lugarEntregaChequeId?: number;
      planId?: number;
      nombrePlan?: string;
      producto?: number;
      capacidad?: number;
      plazo?: number;
      factorActualizacionId?: number;
      montoMinimo?: number;
      montoMaximo?: number;
      montoMaximoGrupoCliente?: number;
      administracion?: string;
      factorMoratorio?: number;
      empresa?: number;
      modeloContratoLegal?: string;
      porcentajeSeguro?: number;
      numeroCuotaPagadaIngresoListaEspera?: number;
      numeroMensualidadSuspende?: number;
      porcentajeCuotaInscripcion?: number;
      porcentajeSegundaCuotaInscripcion?: number;
      porcentajeCesionDerechos?: number;
      porcentajeGastoAdministracionIntegrante?: number;
      porcentajeGastoAdministracionAdjudicatario?: number;
      porcentajeGastoAdministracionAdjudicado?: number;
      porcentajeGastoAdministracionAnticipadoIntegrante?: number;
      porcentajeGastoAdministracionAnticipadoAdjudicatario?: number;
      porcentajeGastoAdministracionAnticipadoAdjudicado?: number;
      porcentajePromocionApertura?: number;
      cuotasPenalizacionCancelacion?: number;
      factorRemanenteVoluntario?: number;
      numeroRecibosGeneraGastoCobranza?: number;
      porcentajeGastoCobranza?: number;
      numeroCuotaPagadaAdjudicacionObligatoria?: number;
      factorCalculoMensualidadIntegrante?: number;
      factorCalculoMensualidadAdjudicatarioSinRemanente?: number;
      factorCalculoMensualidadAdjudicatarioConRemanente?: number;
      montoDisponible?: number;
      estadoProcesal?: number;
      candadoTransacciones?: boolean;
      contrato: Contrato;
    }
  }
}

export namespace Microsoft {
  export namespace AspNetCore {
    export namespace Identity {
      export interface IdentityError {
        code?: string;
        description?: string;
      }
    }
  }
}
